import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
  Snackbar,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { createRef, useEffect, useState } from "react";
import fileIcon from "../../Assets/Images/filePdf.svg";
import "./ItRequestStyles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import "./ItRequestStyles.css";
import {
  useGetItRequestsDataQuery,
  useGetItRequestsByIdQuery,
  useUpdateItRequestsMutation,
  useDeleteItRequestsMutation,
  useCreateItRequestsMutation,
  useGetItRequestByRequestedIdQuery,
  useCreateOtherDetailsMutation,
  useUpdateOtherDetailsMutation,
} from "../../Services/APIs";
import { CSVLink } from "react-csv";
import ApproveRejectFooterButton from "./ApproveRejectFooterButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
//imports for approve/reject button
// import {
//   Box,
//   Button,
//   Popover,
//   TextField,
//   Typography,
//   Grid,
// } from "@mui/material";
// import React, { useState } from "react";
import { Popover } from "@mui/material";
import "./ItRequestStyles.css";
import { Close } from "@mui/icons-material";
import approveIcon from "../../Assets/it-request/approve-info.png";
import rejectIcon from "../../Assets/it-request/reject-info.png";
import closeIcon from "../../Assets/it-request/closed.png";
import commentsIcon from "../../Assets/it-request/comments-icon.png";
import { useParams } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import useLoggedInUser from "../Hooks/useLoggedInUser";

interface FormDataType {
  [x: string]: any;
  priority: string;
  type: string;
  domain: string;
  email: string;
  database: string;
  vpnId: string;
  distribution: string;
  application: string;
  security: string;
  domainId: string;
  emailBox: string;
  isActive: false;
  device: string;
  backup: string;
  devServer: string;
  terminalServer: string;
  fileServer: string;
  systemDetails: string;
  phone: string;
  simPackage: string;
  internetUser: string;
  communication: string;
  itRemark: string;
  comments: string;
}

// import { useLocation } from 'react-router-dom';
import {
  useApproveOrRejectWorkflowRunMutation,
  useGetWorkflowRunByRequestIdQuery,
} from "../../Services/workFlowAPIs";
import OtherDetails from "./OtherDetails";
import LoaderComponent from "../../hooks/LoaderComponent";
import IconText from "../IconText/IconText";
import WorkflowHistory from "./WorkflowHistory";
import PdfExport from "./PdfExport";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}
const ITRequestApproveRejectThirdApproverForm = (props: any) => {
  const { Download, isPdfExport } = props;
  const location = useLocation();
  const { formData, runId, nextApproverId, nextApproverName } =
    location.state || {};
  const navigate = useNavigate();
  const { requestedId } = useParams<{ requestedId: string }>();
  const [approveOrRejectWorkflowRun] = useApproveOrRejectWorkflowRunMutation();
  const {
    data: getITRequestFormDetailsUsingRequestId,
    error: getFormDatausingRequestIdError,
    isLoading: getFormDatausingRequestIdisLoading,
    refetch,
  } = useGetItRequestByRequestedIdQuery(requestedId || "", {
    skip: !requestedId,
  });
  const { data: workflowHistoryData, refetch: refetchWorkflowHistoryData } =
    useGetWorkflowRunByRequestIdQuery(requestedId || "", {
      skip: !requestedId,
    });
  const { employeeData } = useLoggedInUser();
  // const approvalStatus = getITRequestFormDetailsUsingRequestId?.approvalStatus;
  useEffect(() => {
    console.log(requestedId, "request id of the form:");
    console.log("refetch function:", refetch);
    if (requestedId) {
      refetch();
      refetchWorkflowHistoryData();
    }
  }, [requestedId, refetch]);

  const pdfExportComponent = createRef<PDFExport>();

  console.log(
    getITRequestFormDetailsUsingRequestId,
    "getITRequestFormDetailsUsingRequestId:"
  );

  const secondApproverName =
    getITRequestFormDetailsUsingRequestId?.secondApprover;
  const approverLevel = "Level-02"; // Default value
  const secondApproverComments =
    getITRequestFormDetailsUsingRequestId?.secondApproverComments;
  const approvalStatus = getITRequestFormDetailsUsingRequestId?.approvalStatus;

  // Determine the reason text based on approvalStatus
  const reasonText =
    approvalStatus === "Second Level Approved"
      ? "Reason for Approval"
      : approvalStatus === "Second Level Rejected"
        ? "Reason for Rejected"
        : "";

  const [isProcessing, setIsProcessing] = useState(false);
  const [selected1, setSelected1] = useState("");
  const [open1, setOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  console.log(location.state, "use location data's from form submission:");
  const [approverComments, setApproverComments] = useState("");
  const [updatedApprovalStatus, setUpdatedApprovalStatus] = useState("");
  const [updatedOverallStatus, setUpdatedOverallStatus] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >("success");
  const [showAlertForRejectionComments, setShowAlertForRejectionComments] = useState(false);
  // useEffect(() => {
  //   if (!location.state || !location.state.formData) {
  //     console.error("No form data found, redirecting to form page.");
  //     // navigate("/newRequestForm");
  //   }
  // }, [location.state, navigate]);

  // if (!location.state || !location.state.formData) {
  //   return null;
  // }

  // // const { formData } = location.state;
  // console.log(location.state.formData, "form datttttt");
  // for other details
  const [sendItem] = useCreateOtherDetailsMutation();
  const [updateItem] = useUpdateOtherDetailsMutation();
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [priority, setPriority] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [database, setDatabase] = useState<string>("");
  const [vpnId, setVpnId] = useState<string>("");
  const [distribution, setDistribution] = useState<string>("");
  const [application, setApplication] = useState<string>("");
  const [security, setSecurity] = useState<string>("");
  const [domainId, setDomainId] = useState<string>("");
  const [emailBox, setEmailBox] = useState<string>("");
  const [device, setDevice] = useState<string>("");
  const [backup, setBackup] = useState<string>("");
  const [devServer, setDevServer] = useState<string>("");
  const [terminalServer, setTerminalServer] = useState<string>("");
  const [fileServer, setFileServer] = useState<string>("");
  const [systemDetails, setSystemDetails] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [simPackage, setSimPackage] = useState<string>("");
  const [internetUser, setInternetUser] = useState<string>("");
  const [communication, setCommunication] = useState<string>("");
  const [itRemark, setItRemark] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [priorityError, setPriorityError] = useState<string>("");
  const [typeError, setTypeError] = useState<string>("");
  const [domainError, setDomainError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [databaseError, setDatabaseError] = useState<string>("");
  const [vpnIdError, setVpnIdError] = useState<string>("");
  const [distributionError, setDistributionError] = useState<string>("");
  const [applicationError, setApplicationError] = useState<string>("");
  const [securityError, setSecurityError] = useState<string>("");
  const [domainIdError, setDomainIdError] = useState<string>("");
  const [emailBoxError, setEmailBoxError] = useState<string>("");
  const [deviceError, setDeviceError] = useState<string>("");
  const [backupError, setBackupError] = useState<string>("");
  const [devServerError, setDevServerError] = useState<string>("");
  const [terminalServerError, setTerminalServerError] = useState<string>("");
  const [fileServerError, setFileServerError] = useState<string>("");
  const [systemDetailsError, setSystemDetailsError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [simPackageError, setSimPackageError] = useState<string>("");
  const [internetUserError, setInternetUserError] = useState<string>("");
  const [communicationError, setCommunicationError] = useState<string>("");
  const [itRemarkError, setItRemarkError] = useState<string>("");
  const [commentsError, setCommentsError] = useState<string>("");
  const [pdfexport, setPdfexport] = useState<any>(false);
  const [approveRejectthird, setApproveRejectthird] = useState<any>(false);
  const [downloadcheck, setdownloadcheck] = useState<any>("");
  const [hasChanges, setHasChanges] = useState(false);
  const [reqFormData, setReqFormData] = useState<FormDataType>({
    priority: "",
    type: "",
    domain: "",
    email: "",
    database: "",
    vpnId: "",
    distribution: "",
    application: "",
    security: "",
    domainId: "",
    emailBox: "",
    isActive: false,
    device: "",
    backup: "",
    devServer: "",
    terminalServer: "",
    fileServer: "",
    systemDetails: "",
    phone: "",
    simPackage: "",
    internetUser: "",
    communication: "",
    itRemark: "",
    comments: "",
  });
  const resetFormFields = () => {
    setReqFormData({
      priority: "",
      type: "",
      domain: "",
      email: "",
      requestedId: "",
      database: "",
      vpnId: "",
      distribution: "",
      application: "",
      security: "",
      domainId: "",
      emailBox: "",
      isActive: false,
      device: "",
      backup: "",
      devServer: "",
      terminalServer: "",
      fileServer: "",
      systemDetails: "",
      phone: "",
      simPackage: "",
      internetUser: "",
      communication: "",
      itRemark: "",
      comments: "",
    });
  };
  useEffect(() => {
    // pdfDownload1 = Download
    setdownloadcheck("Download");
  }, [Download]);
  const hideAlertHandler = () => {
    // setPdfexport(true);
    setApproveRejectthird(true);
    setdownloadcheck("check");
  };
  useEffect(() => {
    if (pdfexport) {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
      setTimeout(() => {
        setPdfexport(false);
      }, 1000);
    }
  }, [pdfexport]);

  // const hideAlertHandler = () => {
  //   setPdfexport(true)
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  // setTimeout(() => {
  // setPdfexport(false)
  // }, 200);
  // };
  // const hideAlertHandler = () => {
  //   setPdfexport(true)
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save().then(() => {
  //       setPdfexport(false)
  //     });
  //   }
  // };
  // const hideAlertHandler = async () => {
  //   setPdfexport(true); // Set pdfexport to true

  //   if (pdfExportComponent.current) {
  //     await new Promise<void>((resolve) => {
  //       if (pdfExportComponent.current) {
  //       pdfExportComponent.current.save(() => {
  //         resolve(); // Call resolve when save is done
  //       });
  //     }
  //     });
  //   }

  //   setPdfexport(false); // Set pdfexport back to false
  // };
  const handleChangeOther =
    (field: keyof FormDataType) =>
      (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => {
        setHasChanges(true);
        setReqFormData({ ...reqFormData, [field]: event.target.value });

        console.log(field, event, "checkfieldValue");

        // Clear error for the field on change
        // if (field === "priority") setPriorityError("");
        // if (field === "type") setTypeError("");
        // if (field === "domain") setDomainError("");
        // if (field === "database") setDatabaseError("");
        // if (field === "vpnId") setVpnIdError("");
        // if (field === "distribution") setDistributionError("");
        // if (field === "application") setApplicationError("");
        // if (field === "security") setSecurityError("");
        // if (field === "domainId") setDomainIdError("");
        // if (field === "emailBox") setEmailBoxError("");
        // if (field === "device") setDeviceError("");
        // if (field === "backup") setBackupError("");
        // if (field === "devServer") setDevServerError("");
        // if (field === "terminalServer") setTerminalServerError("");
        // if (field === "fileServer") setFileServerError("");
        // if (field === "systemDetails") setSystemDetailsError("");
        // if (field === "phone") setPhoneError("");
        // if (field === "simPackage") setSimPackageError("");
        // if (field === "internetUser") setInternetUserError("");
        // if (field === "communication") setCommunicationError("");
        // if (field === "itRemark") setItRemarkError("");
        // if (field === "comments") setCommentsError("");
        // if (field === "email") setEmailError("");
      };

  const validateForm = () => {
    let formIsValid = true;
    console.log("Form data at validation:", reqFormData);

    if (reqFormData.priority?.trim() === "") {
      setPriorityError("Priority is required");
      formIsValid = false;
    } else {
      setPriorityError("");
    }
    if (reqFormData.type?.trim() === "") {
      setTypeError("Type is required");
      formIsValid = false;
    } else {
      setTypeError("");
    }
    if (reqFormData.domain?.trim() === "") {
      setDomainError("Domain is required");
      formIsValid = false;
    } else {
      setDomainError("");
    }
    if (reqFormData.database?.trim() === "") {
      setDatabaseError("Exchange Database is required");
      formIsValid = false;
    } else {
      setDatabaseError("");
    }
    if (reqFormData.vpnId?.trim() === "") {
      setVpnIdError("VPN ID Created is required");
      formIsValid = false;
    } else {
      setVpnIdError("");
    }
    if (reqFormData.distribution?.trim() === "") {
      setDistributionError("Distribution Groups is required");
      formIsValid = false;
    } else {
      setDistributionError("");
    }
    if (reqFormData.application?.trim() === "") {
      setApplicationError("Application Access Details is required");
      formIsValid = false;
    } else {
      setApplicationError("");
    }
    if (reqFormData.security?.trim() === "") {
      setSecurityError("Security Groups is required");
      formIsValid = false;
    } else {
      setSecurityError("");
    }
    if (reqFormData.domainId?.trim() === "") {
      setDomainIdError("Domain ID Status is required");
      formIsValid = false;
    } else {
      setDomainIdError("");
    }

    if (reqFormData.emailBox?.trim() === "") {
      setEmailBoxError("Email-Box Status is required");
      formIsValid = false;
    } else {
      setEmailBoxError("");
    }
    if (reqFormData.device?.trim() === "") {
      setDeviceError("Device Returned is required");
      formIsValid = false;
    } else {
      setDeviceError("");
    }
    if (reqFormData.backup?.trim() === "") {
      setBackupError("Data Backup Done up is required");
      formIsValid = false;
    } else {
      setBackupError("");
    }
    if (reqFormData.devServer?.trim() === "") {
      setDevServerError("Dev Server Profile Cleaned is required");
      formIsValid = false;
    } else {
      setDevServerError("");
    }
    if (reqFormData.terminalServer?.trim() === "") {
      setTerminalServerError("Terminal Server Profile Cleaned is required");
      formIsValid = false;
    } else {
      setTerminalServerError("");
    }
    if (reqFormData.fileServer?.trim() === "") {
      setFileServerError("File Server Access is required");
      formIsValid = false;
    } else {
      setFileServerError("");
    }
    if (reqFormData.systemDetails?.trim() === "") {
      setSystemDetailsError("System Details is required");
      formIsValid = false;
    } else {
      setSystemDetailsError("");
    }
    const phoneRegex = /^[0-9]{10}$/;
    const phone = reqFormData.phone ?? "";

    if (phone.trim() === "") {
      setPhoneError("Phone Details is required");
      formIsValid = false;
    } else if (!phoneRegex.test(phone)) {
      setPhoneError("Invalid Phone Number. It should be a 10-digit number.");
      formIsValid = false;
    } else {
      setPhoneError("");
      formIsValid = true;
    }
    // if (reqFormData.phone?.trim() === "") {
    //   setPhoneError("Phone Details is required");

    //   formIsValid = false;
    // } else {
    //   setPhoneError("");
    // }
    if (reqFormData.simPackage?.trim() === "") {
      setSimPackageError("Sim Package Details is required");
      formIsValid = false;
    } else {
      setSimPackageError("");
    }
    if (reqFormData.internetUser?.trim() === "") {
      setInternetUserError("Intranet User  Profile Updated  is required");
      formIsValid = false;
    } else {
      setInternetUserError("");
    }
    if (reqFormData.communication?.trim() === "") {
      setCommunicationError("User Communication  is required");
      formIsValid = false;
    } else {
      setCommunicationError("");
    }
    if (reqFormData.itRemark?.trim() === "") {
      setItRemarkError("IT Remark is required");
      formIsValid = false;
    } else {
      setItRemarkError("");
    }
    // if (approverComments === "") {
    if (reqFormData.comments?.trim() === "") {
      setCommentsError("Comments is required");
      formIsValid = false;
    } else {
      setCommentsError("");
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if ((reqFormData.email ?? "")?.trim() === "") {
      setEmailError("E-Mail ID Assigned required");
      formIsValid = false;
    } else if (!emailRegex.test(reqFormData.email ?? "")) {
      setEmailError("Invalid Email Address");
      formIsValid = false;
    } else {
      setEmailError("");
    }

    return {
      formIsValid,
    };
  };

  useEffect(() => {
    validateForm();
  }, [
    reqFormData,
    // priority,
    // type,
    // domain,
    // email,
    // database,
    // vpnId,
    // distribution,
    // application,
    // security,
    // domainId,
    // emailBox,
    // device,
    // backup,
    // devServer,
    // terminalServer,
    // fileServer,
    // systemDetails,
    // phone,
    // simPackage,
    // internetUser,
    // communication,
    // itRemark,
    // comments,
  ]);

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Allow only numeric input and limit to 10 digits
    const value = event.target.value.replace(/\D/g, "").slice(0, 10);

    setReqFormData({ ...reqFormData, phone: value });
    setPhoneError("");
  };
  const handleApproveRejectButtonClose = () => {
    setOpen2(false);
    setAnchorEl(null);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleApprove = async () => {
    const { formIsValid } = validateForm();

    if (!formIsValid) {
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all fields to submit");
      setSnackbarSeverity("error");
      setIsSubmitting(false);
      return;
    }
    console.log("Attempting to approve");
    if (isProcessing) {
      console.log("Already processing");
      return; // Prevent multiple clicks
    }
    setIsSubmitting(true);
    showLoader();

    try {
      const response = await approveOrRejectWorkflowRun({
        runId: getITRequestFormDetailsUsingRequestId.runId,
        approverId: getITRequestFormDetailsUsingRequestId.thirdApproverAdminId,
        approverName: getITRequestFormDetailsUsingRequestId.thirdApproverAdmin,
        action: "Approved",
        // comments: approverComments,
        comments: reqFormData.comments,
        level: 3,
        priority: reqFormData.priority,
        type: reqFormData.type,
        domain: reqFormData.domain,
        email: reqFormData.email,
        database: reqFormData.database,
        vpnId: reqFormData.vpnId,
        distribution: reqFormData.distribution,
        application: reqFormData.application,
        security: reqFormData.security,
        domainId: reqFormData.domainId,
        emailBox: reqFormData.emailBox,
        device: reqFormData.device,
        backup: reqFormData.backup,
        devServer: reqFormData.devServer,
        terminalServer: reqFormData.terminalServer,
        fileServer: reqFormData.fileServer,
        systemDetails: reqFormData.systemDetails,
        phone: reqFormData.phone,
        simPackage: reqFormData.simPackage,
        internetUser: reqFormData.internetUser,
        communication: reqFormData.communication,
        itRemark: reqFormData.itRemark,
      }).unwrap();

      // Update state with new approval status
      const updatedApprovalStatus = response.itRequest.approvalStatus;
      const updatedOverallStatus = response.itRequest.overallStatus;
      setSnackbarMessage("Request approved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      // setUpdatedApprovalStatus(updatedApprovalStatus); // Store the new approval status
      setUpdatedOverallStatus(updatedOverallStatus); // Store the new overall status
      // Handle additional logic after approval, e.g., navigating to another page or updating UI
      console.log(
        "Approve response:",
        response,
        updatedApprovalStatus,
        updatedOverallStatus
      );

      setSnackbarMessage("Request submitted successfully");
      // setTimeout(() => {
      //   navigate("/itRequests", { state: { refresh: true } });
      // }, 1000);
      // Handle additional logic after approval, e.g., navigating to another page or updating UI
    } catch (error) {
      console.error("Error approving request:", error);
      setSnackbarMessage("Failed to submit the request");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);
      setSelected1("");
      // setOpen1(false);
      setActionCompleted(true);
      handleApproveRejectButtonClose();
      hideLoader();
      // Close the popover before navigating
      await handleClose();
      navigate("/itRequests", { state: { refresh: true } });
    }
  };

  const handleRejectFunction = async () => {
    console.log("Attempting to reject");
    if (isProcessing) {
      console.log("Already processing");
      return; // Prevent multiple clicks
    }
    setIsProcessing(true);
    showLoader();
    try {
      const response = await approveOrRejectWorkflowRun({
        runId: getITRequestFormDetailsUsingRequestId.runId,
        approverId: getITRequestFormDetailsUsingRequestId.thirdApproverAdminId,
        approverName: getITRequestFormDetailsUsingRequestId.thirdApproverAdmin,
        action: "Rejected",
        comments: approverComments,
        level: 3,
      }).unwrap();

      // Update state with new approval status
      const updatedApprovalStatus = response.itRequest.approvalStatus;
      const updatedOverallStatus = response.itRequest.overallStatus;
      // setUpdatedApprovalStatus(updatedApprovalStatus); // Store the new approval status
      setUpdatedOverallStatus(updatedOverallStatus); // Store the new overall status
      setSnackbarMessage("Request rejected successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      console.log(
        "Reject response:",
        response,
        updatedApprovalStatus,
        updatedOverallStatus
      );

      setSnackbarMessage("Request rejected successfully");
      // setTimeout(() => {
      //   navigate("/itRequests", { state: { refresh: true } });
      // }, 1000);
      // Handle additional logic after rejection, e.g., navigating to another page or updating UI
    } catch (error) {
      console.error("Error rejecting request:", error);
      setSnackbarMessage("Failed to reject the request");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);
      setSelected1("");
      // setOpen1(false);
      setActionCompleted(true);
      handleApproveRejectButtonClose();
      setOpenPopUp(true);
      hideLoader();
      // Close the popover before navigating
      await handleClose();
      navigate("/itRequests", { state: { refresh: true } });
    }
  };


  const handleReject = async () => {
    if (approverComments == "" || approverComments == undefined) {
      setShowAlertForRejectionComments(true);
    } else {
      setShowAlertForRejectionComments(false);
      // handleRejectFunction();      
    }
  }


  const handleSubmitClick = () => {
    // Submit form logic
    console.log("Form submitted:", formData);
  };
  // const formattedFormData = [formData];
  const formattedFormData = formData
    ? Object.entries(formData).map(([key, value]) => [key, value])
    : [];

  const [formViewData, setFormViewData] = useState(
    location.state?.formData || {}
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormViewData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add a title to the PDF
    doc.text("Form Data", 20, 10);

    doc.autoTable({
      head: [["Field", "Value"]],
      body: formattedFormData.map((row) =>
        Object.entries(row).map(([key, value]) => [
          key.replace(/([A-Z])/g, " $1").trim(), // Add spaces between camelCase words
          value,
        ])
      ),
      styles: {
        cellPadding: 2, // Adjust cell padding to fit content better
        fontSize: 10, // Adjust font size to fit content
        minCellHeight: 10,
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Adjust width of the first column (Field names)
        1: { cellWidth: 150 }, // Adjust width of the second column (Values)
      },
    });

    // Save the PDF
    doc.save("form-data.pdf");
  };

  //states and other details for approve/reject button
  const [selected, setSelected] = useState<string>("approve");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selected2, setSelected2] = useState<string>("Yes Reject");
  const [selected3, setSelected3] = useState<string>("Yes Approve");
  const [showResults, setShowResults] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  const onClickResults = () => {
    setShowResults(true);
  };
  const handleToggleAndClick = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected(btn);
    setAnchorEl(event.currentTarget);
  };
  const handleToggleApprove = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected3(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    setOpenPopUp(false);
    setShowAlertForRejectionComments(false);
  };

  const handleToggleReject = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected2(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };
  const [activeTab, setActiveTab] = useState("requestor");
  const [selectedTab, setSelectedTab] = useState<string>("requestor");

  const handleTabClick = (
    tab: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActiveTab(tab);
    setSelectedTab(tab);
    // setAnchorEl(event.currentTarget); // Uncomment if needed
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const Approve = "third";

  let currentApproverEmail = employeeData?.employeeEmail;
  let currentApproverList =
    getITRequestFormDetailsUsingRequestId?.nextApproverEmail;
  let overallStatus = getITRequestFormDetailsUsingRequestId?.overallStatus;
  let eligibleForAction =
    overallStatus === "Pending" &&
    currentApproverList?.includes(currentApproverEmail);
  console.log(eligibleForAction, "checkeligibleForAction");
  function truncateDescription(description: string, wordLimit: number) {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  }
  return (
    <>
      {approveRejectthird && downloadcheck == "check" ? (
        <PdfExport
          approveRejectthird={approveRejectthird}
          Approve={Approve}
          setApproveRejectthird={setApproveRejectthird}
          requestedId={requestedId}
          workflowHistoryData={workflowHistoryData}
          activeTa={activeTab}
        />
      ) : (
        <div>
          <IconText />
          <div style={{ backgroundColor: "#f1f1f1", marginTop: "0px" }}>
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid
                style={{
                  // height: "40px",
                  backgroundColor: "#D20F3D",
                  // display: "flex",
                  // justifyContent: "space-between",
                  position: "sticky",
                  top: 0,
                  zIndex: 1000,
                }}
              >
                <div>
                  <div className="TableBreadcrumbs">
                    <Link to="/itRequests" className="TableBreadcrumbs1">
                      Dashboard
                    </Link>
                    <span className="breadcrumsIconNew1">
                      <NavigateNextIcon />
                    </span>
                    <span className="Breadcrumbs2">Form</span>
                    <span className="breadcrumsIconNew1">
                      <NavigateNextIcon />
                    </span>
                    <span className="Breadcrumbs2">{requestedId}</span>
                  </div>
                  {!getFormDatausingRequestIdisLoading && (<div className="tab-contain">
                    <div>
                      <Button
                        onClick={(event) => handleTabClick("requestor", event)}
                        className={
                          selectedTab === "requestor"
                            ? "selected"
                            : "notSelected"
                        }
                      >
                        Request Details
                      </Button>
                      {/* <Button
                  onClick={(event) => handleTabClick("other", event)}
                  className={
                    selectedTab === "other" ? "selected" : "notSelected"
                  }
                >
                  Other Details
                </Button> */}
                      <Button
                        onClick={(event) => handleTabClick("work", event)}
                        className={
                          selectedTab === "work" ? "selected" : "notSelected"
                        }
                      >
                        Work Flow History
                      </Button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {/* {!isPdfExport && (
                    <Tooltip title="Download as Pdf">
                      <IconButton
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "4px !important",
                          padding: "5px 10px !important",
                          fontSize: "12px !important",
                          lineHeight: "1.75 !important",
                          "&:hover": {
                            backgroundColor: "white !important",
                          },
                        }}
                        onClick={hideAlertHandler}>
                        <PrintOutlinedIcon style={{ width: "16px ", height: "16px" }} />
                        <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                          Print
                        </span>
                      </IconButton>
                    </Tooltip>
                    )} */}
                        <div
                          className="itformStatus"
                          style={{
                            backgroundColor:
                              updatedApprovalStatus === "Completed"
                                ? "green"
                                : "inherit",
                            color:
                              updatedApprovalStatus === "Completed"
                                ? "white"
                                : "inherit",
                            padding: "4px",
                            borderRadius: "4px",
                          }}
                        >
                          Status :{" "}
                          <span className="approvalStatus">
                            {/* {updatedApprovalStatus ? updatedApprovalStatus : " Approved"} */}
                            {
                              getITRequestFormDetailsUsingRequestId?.approvalStatus
                            }
                          </span>
                        </div>

                        {/* <Button className="submit-req-btn" 
              onClick={generatePDF}
              onClick={hideAlertHandler}
              > */}
                        {/* <CSVLink
                    data={formattedFormData}
                    filename={"form-data.csv"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Print
                  </CSVLink> */}
                        {/* Print
              </Button> */}
                      </div>

                      <div
                      // style={{
                      //   width: "100%",
                      //   backgroundColor: "#f1f1f1",
                      //   padding: "15px 40px",
                      // }}
                      >
                        <div
                        // style={{
                        //   width: "170px",
                        //   display: "flex",
                        //   justifyContent: "space-between",
                        //   float: "right",
                        // }}
                        >
                          {!actionCompleted && eligibleForAction && (
                            <>
                              <Button
                                sx={{ marginRight: "10px" }}
                                aria-describedby={
                                  selected === "reject" ? id : undefined
                                }
                                // className={
                                //   selected === "reject" ? "selected" : "notSelected"
                                // }
                                className="cancel-req-btn"
                                onClick={(event) =>
                                  handleToggleAndClick("reject", event)
                                }
                              >
                                Reject
                              </Button>

                              {eligibleForAction && (
                                <Button
                                  aria-describedby={
                                    selected === "approve" ? id : undefined
                                  }
                                  // className={
                                  //   selected === "approve" ? "selected" : "notSelected"
                                  // }
                                  className="submit-req-btn"
                                  onClick={(event) =>
                                    handleToggleAndClick("approve", event)
                                  }
                                >
                                  Submit
                                </Button>
                              )}

                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                  <Popover
                    id={id}
                    open={open && selected === "reject"}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      padding: "10px",
                      ".MuiPopover-paper": {
                        borderRadius: "20px",
                      },
                    }}
                  >
                    <Grid
                      style={{ margin: "10px", width: "350px" }}
                    >
                      <div
                        style={{ width: "100%", height: "10px" }}
                      >
                        <img
                          src={closeIcon}
                          alt="sdfsf"
                          style={{
                            float: "right",
                            width: "15px",
                            cursor: "pointer",
                          }}
                          onClick={handleClose}
                        />
                      </div>
                      <Grid
                        style={{
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={rejectIcon}
                          alt="rejfrt"
                          style={{ width: "50px" }}
                        />
                      </Grid>
                      <Typography
                        sx={{
                          p: 2,
                          color: "#3e3e3e",
                          textAlign: "center",
                        }}
                      >
                        Are you sure want to reject this form ?
                      </Typography>
                      <Box style={{ width: "100%" }}>
                        <TextField
                          style={{
                            margin: "10px 17px",
                            width: "90%",
                            color: "gray !important",
                            backgroundColor: "#F4F4F4",
                          }}
                          id="Comments"
                          placeholder="Write Comments here"
                          multiline
                          rows={4}
                          name="Comments"
                          value={approverComments}
                          onChange={(e) => {
                            console.log("Comments:", e.target.value);
                            setApproverComments(e.target.value);
                          }}
                        />
                      </Box>
                      <Grid sx={{ m: 2, width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={(event) =>
                              handleToggleReject("No", event)
                            }
                            className={
                              selected2 === "No"
                                ? "selected"
                                : "notSelected"
                            }
                          >
                            No
                          </Button>
                          <Button
                            onClick={handleReject}
                            disabled={isProcessing}
                            className={
                              selected2 === "Yes Reject"
                                ? "selected"
                                : "notSelected"
                            }
                            style={{ marginLeft: "30px" }}
                          >
                            Yes, Reject
                          </Button>
                        </div>
                      </Grid>
                      {showAlertForRejectionComments && <Alert severity="error">Please provide comments to reject the form.</Alert>}
                    </Grid>
                  </Popover>

                  <Popover
                    id={id}
                    open={open && selected === "approve"}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      padding: "10px",
                      ".MuiPopover-paper": {
                        borderRadius: "20px",
                      },
                    }}
                  >
                    <Grid
                      style={{ margin: "10px", width: "350px" }}
                    >
                      <div
                        style={{ width: "100%", height: "10px" }}
                      >
                        <img
                          src={closeIcon}
                          alt="sdfsf"
                          style={{
                            float: "right",
                            width: "15px",
                            cursor: "pointer",
                          }}
                          onClick={handleClose}
                        />
                      </div>
                      <Grid
                        style={{
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={approveIcon}
                          alt="rejfrt"
                          style={{ width: "50px" }}
                        />
                      </Grid>
                      <Typography
                        sx={{
                          p: 2,
                          color: "#3e3e3e",
                          textAlign: "center",
                        }}
                      >
                        Are you sure want to Submit this form ?
                      </Typography>
                      <Box style={{ width: "100%" }}>
                        <div
                          style={{
                            marginLeft: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "90px",
                          }}
                          onClick={onClickResults}
                        >
                          {" "}
                          {/* <img src={commentsIcon} alt="cdfd" />{" "}
                            <span style={{ color: "#ababab" }}>Comments</span> */}
                        </div>
                        {/* {showResults && (
                            <TextField
                              style={{
                                margin: "10px 17px",
                                width: "90%",
                                color: "gray !important",
                                backgroundColor: "#F4F4F4",
                              }}
                              id="Comments"
                              placeholder="Write Comments here"
                              multiline
                              rows={4}
                              name="Comments"
                              value={approverComments}
                              onChange={(e) => {
                                console.log("Comments:", e.target.value);
                                setApproverComments(e.target.value);
                              }}
                            />
                          )} */}
                      </Box>
                      <Grid sx={{ m: 2, width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={(event) =>
                              handleToggleApprove("No", event)
                            }
                            className={
                              selected3 === "No"
                                ? "selected"
                                : "notSelected"
                            }
                          >
                            No
                          </Button>
                          <Button
                            onClick={handleApprove}
                            // disabled={isProcessing}
                            disabled={isSubmitting}
                            className={
                              selected3 === "Yes Approve"
                                ? "selected"
                                : "notSelected"
                            }
                            style={{ marginLeft: "30px" }}
                          >
                            Yes, Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Popover>
                  {/* <Typography className="new-req-text">Form View</Typography> */}
                </div>

                {/* {isLoadingg && <LoaderComponent />} */}
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={4000}
                  onClose={() => setSnackbarOpen(false)}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </Grid>

              {getFormDatausingRequestIdisLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh', // Full viewport height
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <PDFExport
                    paperSize="A4"
                    scale={0.4}
                    keepTogether="p"
                    forcePageBreak=".page-break"
                    ref={pdfExportComponent}
                    fileName={`${requestedId}.pdf`}
                  >
                    {activeTab === "requestor" && (
                      <div
                        style={
                          {
                            // marginBottom: "50px",
                            // height: "80vh",
                          }
                        }
                      >
                        <Box
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            margin: "40px",
                            // width: "90%",
                            padding: "20px 20px",
                            // overflowY: "scroll",
                            // height:"50vh"
                            // overflowY: pdfexport ? "auto" : "scroll",
                            // height: pdfexport ? "auto" : "50vh",
                          }}
                        >
                          <div>
                            <Grid>
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                  color: "#423e44",
                                }}
                              >
                                Request Type
                              </div>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  First Approver
                                </Typography>
                                <TextField
                                  id="firstApprover"
                                  name="firstApprover"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.firstApprover ??
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Request Type
                                </Typography>
                                <TextField
                                  id="requestType"
                                  name="requestType"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.requestType ??
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Entity
                                </Typography>
                                <TextField
                                  id="entity"
                                  name="entity"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.entity ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Location
                                </Typography>
                                <TextField
                                  id="location"
                                  name="location"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.location ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              xs={12}
                              style={{
                                borderBottom: "1px solid #E3E4E5 ",
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                            ></Grid>
                            <Grid>
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                  color: "#423e44",
                                }}
                              >
                                Request For
                              </div>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  {/* User Profile */}
                                  Passport Photo
                                </Typography>
                                <Grid>
                                  {/* <div>
                          <img
                            src={
                              getITRequestFormDetailsUsingRequestId?.employeeImage
                            }
                            alt="Uploaded file"
                            style={{ maxWidth: "100%", height: "100px" }}
                          />
                        </div> */}
                                  <div>
                                    <img
                                      src={
                                        getITRequestFormDetailsUsingRequestId?.employeeImage
                                      }
                                      alt={`${getITRequestFormDetailsUsingRequestId?.fileName}'s Profile`}
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                        marginBottom: "10px",
                                      }}
                                    />
                                    {/* <div
                              style={{
                                backgroundColor: "#fdf3f5",
                                color: "#d9395f",
                                padding: "10px",
                                fontSize: "14px",
                              }}
                            >
                              Selected File:{" "} {getITRequestFormDetailsUsingRequestId?.fileName
    ? getITRequestFormDetailsUsingRequestId.fileName.length > 15
      ? `${getITRequestFormDetailsUsingRequestId.fileName.substring(0, 15)}...`
      : getITRequestFormDetailsUsingRequestId.fileName
    : 'No file selected'}
                            </div> */}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              {/*<Grid item xs={12} md={3}>
                         <Typography className="R-Texts">
                          Request For Myself
                        </Typography>
                        <div>
                          <FormGroup
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "200px",
                            }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Checkbox defaultChecked color="default" />}
                              label="yes"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Checkbox defaultChecked color="default" />}
                              label="No"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </div>
                      </Grid> */}
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  User ID
                                </Typography>
                                <TextField
                                  id="userId"
                                  name="userId"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.userId ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                {" "}
                                Salutation
                              </Typography>
                              <TextField
                                id="salutation"
                                name="salutation"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.salutation ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid> */}
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Employee ID
                                </Typography>
                                <TextField
                                  id="employeeId"
                                  name="employeeId"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.employeeId ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Name
                                </Typography>
                                {/* <TextField
                      id="firstName"
                      name="firstName"
                      className="span"
                      value={reqFormData.firstName}
                      style={{ width: "100%" }}
                      onChange={handleChange("firstName")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    /> */}
                                <div className="input-group">
                                  {/* <select
                                  name="firstName"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.firstName ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                  defaultValue="mr"
                                >
                                  <option value="Mr.">Mr.</option>
                                  <option value="Mrs.">Mrs.</option>
                                  <option value="Miss">Miss</option>
                                </select> */}

                                  {/* <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.lastName ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                placeholder="Enter value here"
                                autoComplete="off"
                              /> */}

                                  <TextField
                                    id="name"
                                    name="name"
                                    className="span"
                                    value={
                                      `${getITRequestFormDetailsUsingRequestId?.salutation} ${getITRequestFormDetailsUsingRequestId?.name}`?.trim() ||
                                      ""
                                    }
                                    style={{ width: "100%" }}
                                    placeholder="Enter value here"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    InputProps={{
                                      readOnly: !isEditable,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Date Of Birth
                                </Typography>
                                <TextField
                                  id="dateOfBirth"
                                  name="dateOfBirth"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.dateOfBirth
                                      ? dayjs(
                                        getITRequestFormDetailsUsingRequestId.dateOfBirth
                                      ).format("DD-MM-YYYY")
                                      : ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                First Name
                              </Typography>
                              <TextField
                                id="firstName"
                                name="firstName"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.firstName ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                Last Name
                              </Typography>
                              <TextField
                                id="lastName"
                                name="lastName"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.lastName ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid> */}
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Designation
                                </Typography>
                                <TextField
                                  id="designation"
                                  name="designation"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.designation ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Department
                                </Typography>
                                <TextField
                                  id="department"
                                  name="department"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.department ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Office Direct No
                                </Typography>
                                <TextField
                                  id="officeDirectNo"
                                  name="officeDirectNo"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.officeDirectNo ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Mobile No
                                </Typography>
                                <TextField
                                  id="mobileNo"
                                  name="mobileNo"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.mobileNo ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Reporting Manager
                                </Typography>
                                <TextField
                                  id="reportingManager"
                                  name="reportingManager"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.reportingManager ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={9}>
                                <Typography className="R-Texts">
                                  Request Details
                                </Typography>
                                <TextField
                                  id="requestDetails"
                                  name="requestDetails"
                                  className="span"
                                  style={{
                                    marginBottom: "10px",
                                    width: "100%",
                                    color: "gray !important",
                                  }}
                                  placeholder="Na"
                                  // multiline
                                  // rows={2}
                                  // maxRows={4}
                                  value={truncateDescription(
                                    getITRequestFormDetailsUsingRequestId?.requestorDetail ||
                                    "",
                                    10
                                  )}
                                  // value={
                                  //   getITRequestFormDetailsUsingRequestId?.requestorDetail ||
                                  //   ""
                                  // }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                    style: {
                                      overflowX: "auto", // Enable horizontal scrolling
                                      whiteSpace: "nowrap", // Prevent text from wrapping
                                    },
                                  }}
                                  inputProps={{
                                    style: {
                                      overflowX: "auto", // Enable horizontal scrolling on the input
                                      whiteSpace: "nowrap", // Prevent text from wrapping
                                      display: "inline-block", // Ensure inline block to allow horizontal scroll
                                      maxWidth: "100%", // Constrain width to container for scrolling
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              xs={12}
                              style={{
                                borderBottom: "1px solid #E3E4E5 ",
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                            ></Grid>
                            <Grid>
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                  color: "#423e44",
                                }}
                              >
                                Request Subject
                              </div>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={6}>
                                <Typography className="R-Texts">
                                  Subject
                                </Typography>
                                <TextField
                                  style={{
                                    marginBottom: "10px",
                                    width: "100%",
                                    color: "gray !important",
                                  }}
                                  placeholder="Na"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.subject ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                    sx: { padding: "1px 1px" },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography className="R-Texts">
                                  Requested By
                                </Typography>
                                <TextField
                                  style={{
                                    marginBottom: "10px",
                                    width: "100%",
                                    color: "gray !important",
                                  }}
                                  placeholder="Na"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.requestedBy ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                    sx: { padding: "1px 1px" },
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Access permission similar to
                                </Typography>
                                <TextField
                                  id="accessPermissionSimilarTo"
                                  name="accessPermissionSimilarTo"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.accessPermissionSimilarTo ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  ERP Company data
                                </Typography>
                                <TextField
                                  id="erpCompanyData"
                                  name="erpCompanyData"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.erpCompanyData ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Preferred email address
                                </Typography>
                                <TextField
                                  id="preferredEmailAddress"
                                  name="preferredEmailAddress"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.preferredEmailAddress ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            {employeeData?.roles?.IT_Admin && (
                              <>
                                <Grid
                                  xs={12}
                                  style={{
                                    borderBottom: "1px solid #E3E4E5 ",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                  }}
                                ></Grid>
                                <Grid>
                                  <div
                                    style={{
                                      fontSize: "18px",
                                      marginBottom: "15px",
                                      color: "#423e44",
                                    }}
                                  >
                                    Other Details
                                  </div>
                                </Grid>
                              </>
                            )}
                            <div>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Priority
                                  </Typography>
                                  <TextField
                                    id="priority"
                                    name="priority"
                                    className="span"
                                    value={reqFormData.priority}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("priority")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("priority")(e);
                                      }
                                    }}
                                    // onChange={handleApproveChange}
                                    placeholder="Enter the priority"
                                    autoComplete="off"
                                  />
                                  <div className="error">{priorityError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Type
                                  </Typography>
                                  <TextField
                                    id="type"
                                    name="type"
                                    className="span"
                                    value={reqFormData.type}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("type")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("type")(e);
                                      }
                                    }}
                                    placeholder="Enter the type"
                                    autoComplete="off"
                                  />
                                  <div className="error">{typeError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Domain
                                  </Typography>
                                  <TextField
                                    id="domain"
                                    name="domain"
                                    className="span"
                                    type="email"
                                    value={reqFormData.domain}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("domain")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("domain")(e);
                                      }
                                    }}
                                    placeholder="Enter the domain"
                                    autoComplete="off"
                                  />
                                  <div className="error">{domainError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    E-Mail ID Assigned
                                  </Typography>
                                  <TextField
                                    id="email"
                                    name="email"
                                    className="span"
                                    type="email"
                                    value={reqFormData.email}
                                    style={{ width: "100%" }}
                                    onChange={handleChangeOther("email")}
                                    // onChange={(e: any) => {
                                    //   const { value } = e.target;
                                    //   const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                    //   if (regex.test(value)) {
                                    //     handleChangeOther("email")(e);
                                    //   }
                                    // }}
                                    placeholder="Enter the E-Mail ID Assigned"
                                    autoComplete="off"
                                  />
                                  <div className="error">{emailError}</div>
                                </Grid>
                              </Grid>
                              {/* <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Approver</Typography>
                    <Autocomplete
                      id="firstApprover"
                      options={firstApproverOptions}
                      value={reqFormData.firstApprover}
                      onChange={(event, newValue) =>
                        handleChange("firstApprover", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="off"
                        />
                      )}
                    />
                    <div className="error">{firstApproverError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <Autocomplete
                      id="requestType"
                      options={requestTypeOptions}
                      value={reqFormData.requestType}
                      onChange={(event, newValue) =>
                        handleChange("requestType", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{requestTypeError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <Autocomplete
                      id="entity"
                      options={entityOptions}
                      value={reqFormData.entity}
                      onChange={(event, newValue) =>
                        handleChange("entity", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{entityError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <Autocomplete
                      id="location"
                      options={locationOptions}
                      value={reqFormData.location}
                      onChange={(event, newValue) =>
                        handleChange("location", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{locationError}</div>
                  </Grid>
                </Grid> */}

                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Exchange Database
                                  </Typography>
                                  <Grid>
                                    <TextField
                                      id="database"
                                      name="database"
                                      className="span"
                                      value={reqFormData.database}
                                      style={{ width: "100%" }}
                                      // onChange={handleChangeOther("database")}
                                      onChange={(e: any) => {
                                        const { value } = e.target;
                                        const regex =
                                          /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                        if (regex.test(value)) {
                                          handleChangeOther("database")(e);
                                        }
                                      }}
                                      placeholder="Enter the Exchange Database"
                                      autoComplete="off"
                                    />
                                    <div
                                      className="error"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      {databaseError}
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    VPN ID Created
                                  </Typography>
                                  <TextField
                                    id="vpnId"
                                    name="vpnId"
                                    className="span"
                                    value={reqFormData.vpnId}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("vpnId")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("vpnId")(e);
                                      }
                                    }}
                                    placeholder="Enter the VPN ID Created"
                                    autoComplete="off"
                                  />
                                  <div className="error">{vpnIdError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Distribution Groups
                                  </Typography>
                                  <TextField
                                    id="distribution"
                                    name="distribution"
                                    className="span"
                                    value={reqFormData.distribution}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("distribution")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("distribution")(e);
                                      }
                                    }}
                                    placeholder="Enter the Distribution Groups"
                                    autoComplete="off"
                                  />
                                  <div className="error">
                                    {distributionError}
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Application Access Details
                                  </Typography>
                                  <TextField
                                    id="application"
                                    name="application"
                                    className="span"
                                    value={reqFormData.application}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("application")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("application")(e);
                                      }
                                    }}
                                    placeholder="Enter the Application Access Details"
                                    autoComplete="off"
                                  />
                                  <div className="error">
                                    {applicationError}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Security Groups
                                  </Typography>
                                  <TextField
                                    id="security"
                                    name="security"
                                    className="span"
                                    value={reqFormData.security}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("security")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("security")(e);
                                      }
                                    }}
                                    placeholder="Enter the Security Groups"
                                    autoComplete="off"
                                  />
                                  <div className="error">{securityError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Domain ID Status
                                  </Typography>
                                  <TextField
                                    id="domainId"
                                    name="domainId"
                                    className="span"
                                    value={reqFormData.domainId}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("domainId")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("domainId")(e);
                                      }
                                    }}
                                    placeholder="Enter the Domain ID Status"
                                    autoComplete="off"
                                  />
                                  <div className="error">{domainIdError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Email-Box Status
                                  </Typography>
                                  <TextField
                                    id="emailBox"
                                    name="emailBox"
                                    className="span"
                                    value={reqFormData.emailBox}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("emailBox")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("emailBox")(e);
                                      }
                                    }}
                                    placeholder="Enter the Email-Box Status"
                                    autoComplete="off"
                                  />
                                  <div className="error">{emailBoxError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Device Returned
                                  </Typography>
                                  <TextField
                                    id="device"
                                    name="device"
                                    className="span"
                                    value={reqFormData.device}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("device")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("device")(e);
                                      }
                                    }}
                                    placeholder="Enter the Device Returned"
                                    autoComplete="off"
                                  />
                                  <div className="error">{deviceError}</div>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Data Backup Done
                                  </Typography>
                                  <TextField
                                    id="backup"
                                    name="backup"
                                    className="span"
                                    value={reqFormData.backup}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("backup")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("backup")(e);
                                      }
                                    }}
                                    placeholder="Enter the Data Backup Done"
                                    autoComplete="off"
                                  />
                                  <div className="error">{backupError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Dev Server Profile Cleaned
                                  </Typography>
                                  <TextField
                                    id="devServer"
                                    name="devServer"
                                    className="span"
                                    value={reqFormData.devServer}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("devServer")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("devServer")(e);
                                      }
                                    }}
                                    placeholder="Enter the Dev Server Profile Cleaned"
                                    autoComplete="off"
                                  />
                                  <div className="error">{devServerError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    {" "}
                                    Terminal Server Profile Cleaned
                                  </Typography>
                                  <TextField
                                    id="terminalServer"
                                    name="terminalServer"
                                    className="span"
                                    value={reqFormData.terminalServer}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther(
                                    //   "terminalServer"
                                    // )}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("terminalServer")(e);
                                      }
                                    }}
                                    placeholder="Enter the Terminal Server Profile Cleaned"
                                    autoComplete="off"
                                  />
                                  <div className="error">
                                    {terminalServerError}
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    File Server Access
                                  </Typography>
                                  <TextField
                                    id="fileServer"
                                    name="fileServer"
                                    className="span"
                                    value={reqFormData.fileServer}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("fileServer")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("fileServer")(e);
                                      }
                                    }}
                                    placeholder="Enter the File Server Access"
                                    autoComplete="off"
                                  />
                                  <div className="error">{fileServerError}</div>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    System Details
                                  </Typography>
                                  <TextField
                                    id=" systemDetails"
                                    name="systemDetails"
                                    className="span"
                                    value={reqFormData.systemDetails}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther(
                                    //   "systemDetails"
                                    // )}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("systemDetails")(e);
                                      }
                                    }}
                                    placeholder="Enter the System Details"
                                    autoComplete="off"
                                  />
                                  <div className="error">
                                    {systemDetailsError}
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Phone Details
                                  </Typography>
                                  <TextField
                                    id="phone"
                                    name="phone"
                                    className="span"
                                    type="text"
                                    inputMode="numeric"
                                    value={reqFormData.phone}
                                    style={{ width: "100%" }}
                                    onChange={handlePhoneChange}
                                    placeholder="Enter value here"
                                    autoComplete="off"
                                  />
                                  <div className="error">{phoneError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Sim Package Details
                                  </Typography>
                                  <TextField
                                    id="simPackage"
                                    name="simPackage"
                                    className="span"
                                    value={reqFormData.simPackage}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("simPackage")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("simPackage")(e);
                                      }
                                    }}
                                    placeholder="Enter the Sim Package Details"
                                    autoComplete="off"
                                  />
                                  <div className="error">{simPackageError}</div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    Intranet User Profile Updated
                                  </Typography>
                                  <TextField
                                    id="internetUser"
                                    name="internetUser"
                                    className="span"
                                    value={reqFormData.internetUser}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("internetUser")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("internetUser")(e);
                                      }
                                    }}
                                    placeholder="Enter the Intranet User Profile Updated"
                                    autoComplete="off"
                                  />
                                  <div className="error">
                                    {internetUserError}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    User Communication
                                  </Typography>
                                  <TextField
                                    id="communication"
                                    name="communication"
                                    className="span"
                                    value={reqFormData.communication}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther(
                                    //   "communication"
                                    // )}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("communication")(e);
                                      }
                                    }}
                                    placeholder="Enter the User Communication"
                                    autoComplete="off"
                                  />
                                  <div className="error">
                                    {communicationError}
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Typography className="R-Texts">
                                    IT Remark
                                  </Typography>
                                  <TextField
                                    id="itRemark"
                                    name="itRemark"
                                    className="span"
                                    value={reqFormData.itRemark}
                                    style={{ width: "100%" }}
                                    // onChange={handleChangeOther("itRemark")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("itRemark")(e);
                                      }
                                    }}
                                    placeholder="Enter the IT Remark"
                                    autoComplete="off"
                                  />
                                  <div className="error">{itRemarkError}</div>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={12} md={6}>
                                  <Typography className="R-Texts">
                                    Comments
                                  </Typography>
                                  <TextField
                                    style={{
                                      width: "100%",
                                      color: "gray !important",
                                    }}
                                    id="comments"
                                    placeholder="Enter the Comments"
                                    multiline
                                    rows={2}
                                    name="comments"
                                    // value={approverComments}
                                    value={reqFormData.comments}
                                    // onChange={handleChangeOther("comments")}
                                    onChange={(e: any) => {
                                      const { value } = e.target;
                                      const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                                      if (regex.test(value)) {
                                        handleChangeOther("comments")(e);
                                      }
                                    }}
                                  // onChange={(e) => {
                                  //   console.log("Comments:", e.target.value);
                                  //   setApproverComments(e.target.value);
                                  // }}
                                  />
                                  <div className="error">{commentsError}</div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </Box>
                      </div>
                    )}
                    {/* {activeTab === "other" && (
                  <div>
                    <Grid item xs={12}>
                      <div style={{ marginTop: "20px" }}>
                        <Box
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            margin: "auto",
                            width: "90%",
                            padding: "20px 20px",
                            overflowY: "scroll",
                            height: "50vh",
                          }}
                        >
                         
                        </Box>
                      </div>
                    </Grid>
                  </div>
                )} */}
                    {activeTab === "work" && (
                      <div
                        className="page-break"
                        style={{ marginBottom: "20px", marginTop: "20px" }}
                      >
                        <Box
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            margin: "40px",
                            padding: "20px 20px",
                            // width: "90%",
                            // padding: "20px 20px",
                            // height: "50vh",
                            // overflowY: "scroll",
                          }}
                        >
                          {/* {workflowHistoryData?.approvalLogs?.length == 0 ? (
                        <div
                          style={{
                            marginBottom: "20px",
                            //   marginTop: "20px",
                            backgroundColor: "#FFF5D4",
                            padding: "10px 10px",
                          }}
                        >
                          <Grid>
                            <div
                              className="error"
                            // style={{ marginLeft: "10px" }}

                            >
                              Request Form Submitted for Approval/Rejection
                            </div>
                          </Grid>
                        </div>
                      ) : ( */}
                          <>
                            <WorkflowHistory
                              workflowHistoryData={workflowHistoryData}
                            />
                          </>
                          {/* )} */}
                        </Box>
                      </div>
                    )}
                  </PDFExport>
                </>
              )}
            </Grid>
            <div
            // style={{
            //   width: "100%",
            //   backgroundColor: "#f1f1f1",
            //   marginBottom: "75px",
            //   padding: "0px 40px",
            // }}
            >
              {/* <div
              style={{
                width: "170px",
                display: "flex",
                justifyContent: "space-between",
                float: "right",
              }}
            >
              <Button className="cancel-req-btn" disabled={isEditable} onClick={handleEditClick}>
               Edit
              </Button>
              <Button className="submit-req-btn" onClick={handleSubmitClick}>
                Submit
              </Button>
            </div> */}
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ITRequestApproveRejectThirdApproverForm;
