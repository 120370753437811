import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
  TextField,
  InputAdornment,
  Box,
  Typography,
  CircularProgress,
  Button,
  Drawer,
  Avatar,
  Dialog,
  Snackbar,
  PaginationItem,
  IconButton,
  Tooltip,
  Divider,
  Grid,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import SearchIcon from "../../Assets/Images/flowbite_search-solid.svg";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Vector from "../../Assets/Images/foundation_list.svg";
import logo from "../../Assets/Images/logo_white.svg";
import Pagination from "@mui/material/Pagination";
import Big from "../../Assets/Images/User-150.svg";
import small from "../../Assets/Images/typcn_user.svg";
import nextIcon from "../../Assets/Images/flowbite_arrow-left-outline.svg";
import PreveiwIcon from "../../Assets/Images/flowbite_arrow-left-outline (1).svg";
import Nameicon from "../../Assets/Images/Vector (3).svg";
import person from "../../Assets/Images/typcn_user (4).svg";
import Excel from "../../Assets/Images/excel.svg";
import EarthIcon1 from "../../Assets/Images/Vector (4).svg";
import EarthIcon2 from "../../Assets/Images/Vector (4).svg";
import Mobileicon from "../../Assets/Images/Vector (2).svg";
import BigDefault from "../../Assets/Images/User-150.svg";
import Small2 from "../../Assets/Images/Group 1724.svg";
import AddUser from "../../Assets/Images/typcn_user (2).svg";
import AddMultipleUsersIcon from "../../Assets/Images/typcn_user (3).svg";
import Mailicon from "../../Assets/Images/uiw_mail.svg";
import Small from "../../Assets/Images/mdi_share.svg";
import GroupIcon from "../../Assets/Images/Group.svg";
import PersonIcon from "@mui/icons-material/Person";
import TuneIcon from "../../Assets/Images/mage_filter-fill.svg";
import DashboardIcon from "../../Assets/Images/mingcute_grid-fill.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Asterisk from "../../Assets/Images/Asterisk.svg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Stack } from "@mui/system";
import ImgUpload from "./ImgUpload";
import "./styles.css";
import FileDialog from "./FileDialog";
import {
  useCreateEmployeeMasterDataMutation,
  useUpdateEmployeeMasterDataMutation,
  useDeleteEmployeeMasterDataMutation,
  useGetEmployeeMasterDataQuery,
  useUploadEmployeeDataMutation,
  useUploadProfileImagesZipfileMutation,
} from "../../Services/APIs";
import AlertYesNo from "../Hooks/DialogYesNo";
import * as XLSX from "xlsx";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";
import man from "../../Assets/Images/DummyProfile.jpg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import calenderIcon from "../../Assets/Images/calenderGrey.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { JSX } from "react/jsx-runtime";
import byrneIcon from "../../Assets/Images/Byrne-logo.png";
import PublishIcon from '@mui/icons-material/Publish';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FileDialog1 from "./FileDialog";
import FileDialog12 from "./FileUploadComponent";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "../../hooks/FilterMenu";
import { format, isValid, startOfToday } from "date-fns";
import SearchBar from "../../hooks/SearchBar";


interface GridValidRowModel {
  designation: any;
  id: any;
  _id: any;
  Id: number;
  name: string;
  employeeName: string;
  employeeId: any;
  employeeCode: number;
  department: string;
  permission: string;
  personalEmail: string;
  reportingTo: any;
  dateAdded: string;
  mobileNumber: string;
  email: string;
  profilePhoto: string;
  role: string;
  Ext?: string;
  Location?: string;
  SubLocation?: string;
  profileIcon?: string;
  address?: string;
  DirectNo?: string;
  dateOfJoining?: string; // Added this property
  dateOfBirth?: Date; // Added this property
  reportingManager?: Date; // Added this property

  // Add other fields as needed
}

interface RowData {
  [key: string]: any; // Replace with specific fields if known
}

interface Employee {
  designation: any;
  id: any;
  _id: any;
  Id: number;
  name: string;
  employeeName: string;
  employeeId: any;
  employeeCode: number;
  department: string;
  permission: string;
  personalEmail: string;
  reportingTo: any;
  dateAdded: string;
  mobileNumber: string;
  email: string;
  profilePhoto: string;
  role: string;
  Ext?: string;
  Location?: string;
  SubLocation?: string;
  profileIcon?: string;
  address?: string;
  DirectNo?: string;
}
interface Props {
  onChange: (file: File) => void;
  // If `src` is supposed to be a prop, define its type here
  src: string; // Assuming `src` is a string holding the initial image URL
}
interface FileDialogProps {
  open: boolean;
  onClose: () => void;
  onFileAccepted: (file: File) => void;
}
interface EmployeeMasterDetailsProps {
  employeeId: string;
}
// export default function EmployeeEditor() {
//   const { data: employeeData, refetch, isLoading,
//     isSuccess, } = useGetEmployeeMasterDataQuery();
// DataGrid configuration

interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);

const EmployeeEditor: React.FC<EmployeeMasterDetailsProps> = ({
  employeeId,
}) => {
  const {
    data: employeeData,
    // error,
    isLoading,
    isSuccess,
    refetch,
  } = useGetEmployeeMasterDataQuery();
  console.log("employeeData", employeeData);
  const [sendEmployeeData] = useCreateEmployeeMasterDataMutation();
  const [updateEmployeeData] = useUpdateEmployeeMasterDataMutation();
  const [deleteEmployeeData] = useDeleteEmployeeMasterDataMutation();
  const [UploadProfileImagesZipfile] = useUploadProfileImagesZipfileMutation();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  const [page, setPage] = useState(1); // Initial page state
  const [pageSize, setPageSize] = useState(15); // Initial page size state
  //Filter Function
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [filterField, setFilterField] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  const [filterSelectedOptions, setFilterSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [paginatedRows, setPaginatedRows] = useState<GridValidRowModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  // useEffect(() => {
  //   const startIndex = (page - 1) * pageSize;
  //   setPaginatedRows(filteredRows.slice(startIndex, startIndex + pageSize));
  // }, [page, pageSize, filteredRows]);
  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(filteredRows.length, startIndex + pageSize); // Fix end index calculation
    setPaginatedRows(filteredRows.slice(startIndex, endIndex));
  }, [page, pageSize, filteredRows]);
  // useEffect(() => {
  //   const newFilteredRows = rows.filter((row: { [x: string]: any; }) =>
  //     columns.some((column) =>
  //       String(row[column.field as keyof GridValidRowModel])
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase())
  //     )
  //   );
  //   setFilteredRows(newFilteredRows);
  //   // setPage(1);
  // }, [searchTerm, rows]);

  useEffect(() => {
    const newFilteredRows = rows.filter((row: GridValidRowModel) =>
      columns.some((column) =>
        String(row[column.field as keyof GridValidRowModel])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) || 
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
      row.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredRows(newFilteredRows);
    // Uncomment the following line if you want to reset the page to 1 on filter change
    // setPage(1);
  }, [searchTerm, rows]);

  const handleFilterIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterField(field);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterField(null);
  };

  const handleFilterChange = (field: string, selectedOptions: string[]) => {
    setFilterSelectedOptions(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    // If no options are selected, display all rows
    if (selectedOptions.length === 0) {
      setFilteredRows(rows);
      return;
    }

    // Filter rows based on the selected options for the field
    const newFilteredRows = rows.filter((row) => {
      const cellValue = String((row as any)[field]).toLowerCase();

      // Check if the cell value matches any of the selected options
      return selectedOptions.some(option =>
        cellValue.includes(option.toLowerCase()) && cellValue !== ""
      );
    });

    setFilteredRows(newFilteredRows);
    setPage(1);
  };

  // const rows =
  //   isSuccess && employeeData
  //     ? employeeData.map((employee: any, index: number) => ({
  useEffect(() => {
    if (isSuccess && employeeData) {
      const formattedRows = employeeData?.map(
        (employee: any, index: number) => ({
          id: employee._id,
          Id: index + 1,
          name: employee.employeeName,
          department: employee.department,
          designation: employee.designation,
          role: employee.role,
          permission: employee.role, // Assuming role is the permission
          dateAdded: employee.dateOfJoining
            ? new Date(employee.dateOfJoining).toDateString()
            : new Date().toDateString(), // Assuming dateOfJoining is a valid date string
          email: employee.employeeEmail,
          mobileNumber: employee.mobileNumber,
          employeeCode: employee.employeeCode,
          personalEmail: employee.personalEmail,
          dateOfJoining: employee.dateOfJoining,
          dateOfBirth: employee.dateOfBirth,
          profilePhoto: employee.profileIcon,
          reportingManager: employee.reportingTo,
          Ext: employee.Ext,
          Location: employee.Location,
          address: employee.address,
          DirectNo: employee.DirectNo,
          SubLocation:employee.SubLocation,
          // }))
          // : [];
        })
      );
      setRows(formattedRows);
      setFilteredRows(formattedRows);
      setIsLoadingg(false);
    }
  }, [isSuccess, employeeData]);


  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  // const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);


  const handleClick12 = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setActionAnchorEl(event.currentTarget);
    setSelectedRowId(id); // Store the selected row ID here
  };



  const columns: GridColDef[] = [
    {
      field: "Id",
      headerName: "S.no",
      width: 20,
      headerClassName: "NewTableHeader",
    },
    // {
    //   field: "profile",
    //   headerName: "Name",
    //   width: 400,
    //   sortable: true,
    //   headerClassName: "TableHeader",
    //   renderCell: (params) => (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <Avatar alt={params.row.name} src={params.row.profilePhoto} />
    //       <div style={{ marginLeft: "10px" }}>
    //         <div className="rowName">{params.row.name}</div>
    //         <div className="rowEmail">{params.row.email}</div>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      field: "profile",
      headerName: "Name",
      width: 400,
      sortable: true,
      headerClassName: "NewTableHeader",
      renderCell: (params) => {
        // Helper function to truncate text
        const truncate = (text: string, maxLength: number) => {
          if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
          }
          return text;
        };
        const isTruncated = params.row.name.length > 50; // Check if name is truncated

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar alt={params.row.name} src={params.row.profilePhoto} />
            <div style={{ marginLeft: "10px" }}>
              <Tooltip title={isTruncated ? params.row.name : ""} placement="top">
                <div className="rowName">{truncate(params.row.name, 50)}</div>
              </Tooltip>
              <div className="rowEmail">{params.row.email}</div>
            </div>
          </div>
        );
      },
      sortComparator: (v1, v2, params1, params2) => {
        const name1 = params1.api.getRow(params1.id).name;
        const name2 = params2.api.getRow(params2.id).name;
        return name1.localeCompare(name2);
      },
    },

    // {
    //   headerClassName: "TableCellHeader",
    //   cellClassName: "TableCellContent",
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 100,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <div style={{ display: "flex", justifyContent: "space-between" }}>
    //       <IconButton onClick={handleClick1}>
    //         <MoreVertIcon />
    //       </IconButton>
    //       <Menu
    //         id="basic-menu"
    //         anchorEl={actionAnchorEl}
    //         open={open1}
    //         onClose={handleClose1}
    //         MenuListProps={{
    //           "aria-labelledby": "basic-button",
    //         }}
    //       >
    //         <MenuItem onClick={() => {
    //           handleEditClick(params.row.id);
    //           handleClose1();
    //         }}
    //           sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}>
    //           <img
    //             style={{ height: "20px", width: "17px" }}
    //             src={AddUser}
    //             alt="Profil Icon "
    //           />
    //           <span>Edit user</span>
    //         </MenuItem>
    //         <MenuItem onClick={() => {
    //           handleRemove(params.row.id);
    //           handleClose1();
    //         }}
    //           sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}>
    //           <img
    //             style={{ height: "20px", width: "17px" }}
    //             src={AddUser}
    //             alt="Profil Icon "
    //           />
    //           <span>Delete user</span>
    //         </MenuItem>
    //       </Menu>
    //     </div>
    //   ),
    //   renderHeader: () => <div style={{ marginLeft: "12px" }}>Actions</div>,
    // },
    {
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params: any) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={(event) => handleClick12(event, params.row.id)}>
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={actionAnchorEl}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                if (selectedRowId !== null) { // Ensure it's not null
                  handleEditClick(selectedRowId); // Use selectedRowId
                  handleClose1();
                }
              }}
              // onClick={() => {
              //   handleEditClick(params.row.id);
              //   handleClose1();
              // }}
              sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}
            >
              <img
                style={{ height: "20px", width: "17px" }}
                src={AddUser}
                alt="Profile Icon"
              />
              <span>Edit user</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (selectedRowId !== null) { // Ensure it's not null
                  handleRemove(selectedRowId); // Use selectedRowId
                  handleClose1();
                }
              }}
              sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}
            >

              <img
                style={{ height: "20px", width: "17px" }}
                src={AddUser}
                alt="Profile Icon"
              />
              <span>Delete user</span>
            </MenuItem>
          </Menu>
        </div>
      ),
      renderHeader: () => <div style={{ marginLeft: "12px" }}>Actions</div>,
    },
    {
      field: "employeeCode",
      headerName: "Employee Code",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
    },
    {
      field: "Ext",
      headerName: "Extension",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
    },
    {
      field: "department",
      headerName: "Department",
      width: 150,
      headerClassName: "NewTableHeader",
      sortable: false,
      cellClassName: "EmployeeMastertablecellcontent",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderCell: (params: any) => (
        // <Tooltip title={params.value ? params.value : "No data available"}  placement="left">
        <div>{params.value ? params.value : "----"}</div>
        // </Tooltip>
      ),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: "auto", cursor: "default" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    // { field: 'permission', headerName: 'Permission', width: 150, headerClassName: 'TableHeader', cellClassName: 'EmployeeMastertablecellcontent'  },
    {
      field: "reportingManager",
      headerName: "Reporting Manager",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
    },
    {
      field: "dateOfJoining",
      headerName: "Date Of Joining",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
      valueFormatter: (params) => {
        // Check if value exists and is a valid date
        if (!params.value) return "---";
        const date = new Date(params.value);
        return isNaN(date.getTime()) ? "---" : date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
      valueFormatter: (params) => {
        // Check if value exists and is a valid date
        if (!params.value) return "---";
        const date = new Date(params.value);
        return isNaN(date.getTime()) ? "---" : date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },    
    // {
    //   field: "dateOfJoining",
    //   headerName: "Date Of Joining",
    //   width: 150,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EmployeeMastertablecellcontent",
    //   valueFormatter: (params) => {
    //     // Extract the date from the parameter
    //     const date = new Date(params.value);

    //     // Format the date to display only the date portion
    //     return date?.toLocaleDateString("en-US", {
    //       year: "numeric",
    //       month: "2-digit",
    //       day: "2-digit",
    //     });
    //   },
    // },
    // {
    //   field: "dateOfBirth",
    //   headerName: "Date Of Birth",
    //   width: 150,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EmployeeMastertablecellcontent",
    //   valueFormatter: (params) => {
    //     // Extract the date from the parameter
    //     const date = new Date(params.value);

    //     // Format the date to display only the date portion
    //     return date?.toLocaleDateString("en-US", {
    //       year: "numeric",
    //       month: "2-digit",
    //       day: "2-digit",
    //     });
    //   },
    // },
    {
      field: "mobileNumber",
      headerName: "MobileNumber",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
    },
    {
      field: "Location",
      headerName: "Location",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
    },
    {
      field: "SubLocation",
      headerName: "Sub-Location",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
    },
    {
      field: "address",
      headerName: "Address",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
    },
    {
      field: "DirectNo",
      headerName: "DirectNo",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "EmployeeMastertablecellcontent",
    },
    // {
    //   field: "personalEmail",
    //   headerName: "Personal Email",
    //   width: 150,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EmployeeMastertablecellcontent",
    // },
    // { field: 'department', headerName: 'department', width: 150, headerClassName: 'custom-header-class', cellClassName: 'custom-cell-class' },
    // { field: 'role', headerName: 'Role', width: 150, headerClassName: 'custom-header-class', cellClassName: 'custom-cell-class' },
    // { field: 'permission', headerName: 'Permission', width: 150, headerClassName: 'custom-header-class', cellClassName: 'custom-cell-class' },
    // { field: 'dateAdded', headerName: 'Date added', width: 150, headerClassName: 'custom-header-class' , cellClassName: 'custom-cell-class' },
    // { field: 'mobileNumber', headerName: 'MobileNumber', width: 150, headerClassName: 'custom-header-class', cellClassName: 'custom-cell-class' },
    // { field: 'employeeCode', headerName: 'Employee Code', width: 150, headerClassName: 'custom-header-class', cellClassName: 'custom-cell-class' },

    {
      field: "viewProfile",
      headerName: "View Profile",
      width: 150,
      renderCell: (params) => (
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleViewProfile(params.row)}
          className="EmployeeMastertablecellcontent"
        >
          <OpenInNewIcon sx={{ height: "17px" }} />
          View Profile
        </Button>
      ),
      headerClassName: "NewTableHeader",
    },
    // {
    //   field: "icon",
    //   headerName: "",
    //   width: 10,
    //   renderCell: (params) => (
    //     <div
    //       onClick={handleClick2}
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <MoreVertIcon />
    //     </div>
    //   ),
    //   headerClassName: "NewTableHeader",
    // },


  ];

  // useEffect(() => {
  //   if (!columns) return; // Ensure columns is defined
  
  //   const newFilteredRows = rows.filter((row: GridValidRowModel) =>
  //     columns.some((column) =>
  //       String(row[column.field as keyof GridValidRowModel])
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase())
  //     ) ||
  //     (row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase()))
  //   );
  
  //   setFilteredRows(newFilteredRows);
  // }, [searchTerm, rows, columns]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [Name, setName] = useState<any>("");
  const [Mail, setMail] = useState<any>("");
  const [profilePhoto, setProfilePhoto] = useState<any>("");
  const [profilePhotooError, setProfilePhotoError] = useState<any>("");
  const [department, setdepartment] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [reportingManager, setReportingManager] = useState<any>("");
  // const [dateofJoining, setDateofJoining] = useState<any>("");
  const [dateofJoining, setDateofJoining] = useState<Date | null>(null);
  const [dateOfBirth, setDateofBirth] = useState<Date | null>(null);
  // const [dateOfBirth, setDateofBirth] = useState<any>("");
  const [employeeCode, setEmployeeCode] = useState<any>("");
  const [extension, setExtension] = useState<any>("");
  const [designation, setDesignation] = useState<any>("");
  const [personalEmail, setPersonalEmail] = useState<any>("");
  const [location, setLocation] = useState<any>("");
  const [subLocation, setSubLocation] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [directNo, setDirectNo] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState<any>("");

  const [NameError, setNameError] = useState<any>("");
  const [MailError, setMailError] = useState<any>("");
  const [departmentError, setdepartmentError] = useState<any>("");
  const [roleError, setRoleError] = useState<any>("");
  const [reportingManagerError, setReportingManagerError] = useState<any>("");
  const [extensionError, setExtensionError] = useState<any>("");
  const [dateofJoiningError, setDateofJoiningError] = useState<any>("");
  const [dateOfBirthError, setDateofBirthError] = useState<any>("");
  const [employeeCodeError, setEmployeeCodeError] = useState<any>("");
  const [designationError, setDesignationError] = useState<any>("");
  const [personalEmailError, setPersonalEmailError] = useState<any>("");
  const [locationError, setLocationError] = useState<any>("");
  const [subLocationError, setSubLocationError] = useState<any>("");
  const [addressError, setAddressError] = useState<any>("");
  const [directNoError, setDirectNoError] = useState<any>("");
  const [mobileNumberError, setMobileNumberError] = useState<any>("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>("");

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [uploadEmployeeData] = useUploadEmployeeDataMutation(); // Mutation for uploading employee data
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  console.log("alertActivation", alertActivation)
  const [alertActivationForCancel, setAlertActivationForCancel] =
    useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");

  const names = Name?.split(" "); // Split the full name into an array of individual names
  const [openOne, setOpenOne] = React.useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const convertDateFormat = (inputDate: string): string => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };



  const handleEditClick = (id: any) => {
    setIsDrawerOpen(true);
    setOpenOne(true);
    setIsEditMode(true);
    console.log(id, "editId");
    setDataId(id);

    const data = rows.filter((i: any) => {
      return i.id == id;
    });
    const dateOfJoining = data[0]?.dateOfJoining ? new Date(data[0].dateOfJoining) : null;
    const dateOfBirth = data[0]?.dateOfBirth ? new Date(data[0].dateOfBirth) : null;

    setName(data[0]?.name);
    setSelectedFiles(data[0]?.profilePhoto);
    setFileSelected(data[0]?.profilePhoto);
    setEmployeeCode(data[0]?.employeeCode);
    setdepartment(data[0]?.department);
    setExtension(data[0]?.Ext);
    setPersonalEmail(data[0]?.personalEmail);
    setReportingManager(data[0]?.reportingManager);
    setDesignation(data[0]?.designation);
    setMobileNumber(data[0]?.mobileNumber);
    setMail(data[0]?.email);
    setRole(data[0]?.role);
    setLocation(data[0]?.Location);
    setSubLocation(data[0]?.SubLocation)
    setAddress(data[0]?.address)
    setDirectNo(data[0]?.DirectNo)
    setDateofJoining(dateOfJoining);
    setDateofBirth(dateOfBirth);
    // setAddress(data[0]?.address);
    console.log(data, "data");
  };


  let defaultProfileIcon = "";

  if (names) {
    for (const name of names) {
      if (name) {
        defaultProfileIcon += name.charAt(0).toUpperCase();
      }
    }
  }
  // Calculate the starting index of rows to display based on current page and page size
  const startIndex = (page - 1) * pageSize;
  // Slice the rows array to get the subset of rows to display on the current page
  // const paginatedRows = rows.slice(startIndex, startIndex + pageSize);

  const [selectedRow, setSelectedRow] = useState<Employee | null>(null);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] =
    useState<boolean>(false);
  const [activeButton, setActiveButton] = useState(null);
  const [dataId, setDataId] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Set the number of items per page
  const endIndex = startIndex + itemsPerPage;

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorE2);

  const handleClick2: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const handleDisable = () => {
    setAnchorE2(null);
  };
  const handleRemove = (id: any) => {
    // Set the dataId value to id
    // deleteEmployeeData(id);
    setDataId(id);
    console.log("idddd", id)
    refetch(); // Assuming refetch is a function to fetch updated data
    setAlertContent("Do you want to delete this data");
    setAlertActivation(true);
    setAnchorE2(null);
  };

  const handleViewProfile = (row: Employee) => {
    setSelectedRow(row);
    setIsProfileDrawerOpen(true);
  };
  const handleCloseProfileDrawer = () => {
    setSelectedRow(null);
    setIsProfileDrawerOpen(false);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage); // Update the page state
  };

  // Slice the rows array based on the current page
  const currentPageRows = rows.slice(startIndex, endIndex);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
    setActionAnchorEl(actionAnchorEl ? null : event.currentTarget);
  };

  const handleClose1 = () => {
    setActionAnchorEl(null);
  };

  const open1 = Boolean(actionAnchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleButtonClick = (buttonName: any) => {
    setActiveButton(buttonName);
  };

  const handleFileAccepted = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      // Upload the file using the mutation
      await uploadEmployeeData(formData);

      // Refresh data table or do necessary actions upon successful upload
      // Example: refetch();
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error
    }
    refetch();
    setSnackbarOpen(true);
    setSnackbarMessage("User Added successfully");
  };

  const [dialogOpen1, setDialogOpen1] = useState(false);

  const handleOpenDialog1 = () => {
    setDialogOpen1(true);
  };

  const handleCloseDialog1 = () => {
    setDialogOpen1(false);
  };

  const handleFileAccepted1 = (file: File) => {
    console.log("Accepted file:", file);
    // Handle the accepted file (e.g., upload it to the server)
  };
  const refreshTableData = () => {
    // Logic to refresh table data
    console.log("Table data refreshed");
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "application/zip") {
      setSelectedFile(file);
    } else {
      alert("Please select a zip file.");
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("zipfile", selectedFile);

    try {
      // Upload the zip file to the server
      const response = await UploadProfileImagesZipfile(formData).unwrap();
      alert("File uploaded successfully!");
      console.log(response);
    } catch (error) {
      alert("File upload failed. Please try again.");
      console.error("Error uploading file:", error);
    }
  };


  const handleChangeTitleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
    if (regex.test(inputValue) && inputValue.length <= 50) {
      // If the input passes the validation and is within the character limit, update the state
      setName(inputValue);
      setNameError("");
    } else if (inputValue.length > 50) {
      // If the input exceeds the character limit, set an error message
      setNameError("Title cannot exceed 50 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setNameError("Please enter only letters, numbers, and spaces.");
    }
  };


  const handleChangeField = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: Function,
    setError: Function
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setState(inputValue);
      setError("");
    } else {
      // If the input contains invalid characters, set an error message
      setError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleChangeMailField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(event.target.value);
    setMail(event.target.value);
    setMailError('');
  };
  const handleChangePersonalEmailField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target.value);
    // setPersonalEmail(event.target.value);
  };
  const handleChangeLocationField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocation(event.target.value);
  };
  const handleChangeSubLocationField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubLocation(event.target.value);
  };
  const handleChangeAddressField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddress(event.target.value);
  };
  const handleChangeDirectNoField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDirectNo(event.target.value);
  };
  // const handleChangeEmployeeCodeField = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const inputValue = event.target.value;
  //   const regex = /^[a-zA-Z0-9\s]*$/; // Allow letters, numbers, spaces, '@', and '.'
  //   if (regex.test(inputValue)) {
  //     // If the input passes the validation, update the state
  //     setEmployeeCode(inputValue);
  //     setEmployeeCodeError("");
  //   } else {
  //     // If the input contains invalid characters, set an error message
  //     setEmployeeCodeError("Please enter only letters, numbers, and spaces.");
  //   }
  // };
  const handleChangeEmployeeCodeField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
    if (regex.test(inputValue) && inputValue.length <= 70) {
      // If the input passes the validation and is within the character limit, update the state
      setEmployeeCode(inputValue);
      setEmployeeCodeError("");
    } else if (inputValue.length > 70) {
      // If the input exceeds the character limit, set an error message
      setEmployeeCodeError("Title cannot exceed 70 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setEmployeeCodeError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleChangeDesignationField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow letters, numbers, spaces, '@', and '.'
    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setDesignation(inputValue);
      setDesignationError("");
    } else {
      // If the input contains invalid characters, set an error message
      setDesignationError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleClick3 = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleChangedepartmentField = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const inputValue = event.target.value;
  //   const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
  //   if (regex.test(inputValue)) {
  //     // If the input passes the validation, update the state
  //     setdepartment(inputValue);
  //     setdepartmentError("");
  //   } else {
  //     // If the input contains invalid characters, set an error message
  //     setdepartmentError("Please enter only letters, numbers, and spaces.");
  //   }
  // };
  const handleChangedepartmentField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
    if (regex.test(inputValue) && inputValue.length <= 50) {
      // If the input passes the validation and is within the character limit, update the state
      setdepartment(inputValue);
      setdepartmentError("");
    } else if (inputValue.length > 50) {
      // If the input exceeds the character limit, set an error message
      setdepartmentError("Title cannot exceed 50 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setdepartmentError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleChangeRoleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setRole(inputValue);
      setRoleError("");
    } else {
      // If the input contains invalid characters, set an error message
      setRoleError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleChangeExtensionField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setExtension(inputValue);
      setExtensionError("");
    } else {
      // If the input contains invalid characters, set an error message
      setExtensionError(
        "Please enter only letters, numbers, and spaces."
      );
    }
  };
  const handleChangeReportingManagerField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces
    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setReportingManager(inputValue);
      setReportingManagerError("");
    } else {
      // If the input contains invalid characters, set an error message
      setReportingManagerError(
        "Please enter only letters, numbers, and spaces."
      );
    }
  };
  // const handleChangeDateofJoiningField = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   console.log(event.target.value);
  //   setDateofJoining(event.target.value);
  //   setDateofJoiningError("");
  // };
  const handleChangeDateofJoiningField = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      const year = date.getFullYear();
      if (year >= 1900 && year <= new Date().getFullYear()) {
        setDateofJoining(date);
        setDateofJoiningError("");
      } else {
        setDateofJoining(null);
        setDateofJoiningError("Please Enter valid date");
      }
    } else {
      setDateofJoining(null);
      setDateofJoiningError("Please select a valid date");
    }
  };
  const handleChangeDateofBirthField = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      const year = date.getFullYear();
      if (year >= 1900 && year <= new Date().getFullYear()) {
        setDateofBirth(date);
        setDateofBirthError("");
      } else {
        setDateofBirth(null);
        setDateofBirthError("Please Enter valid date");
      }
    } else {
      setDateofBirth(null);
      setDateofBirthError("Please select a valid date");
    }
  };
  // const handleChangeDateofBirthField = (date: Date | null) => {
  //   if (date && !isNaN(date.getTime())) {
  //     try {
  //       console.log(date.toISOString());
  //       setDateofBirth(date);
  //       setDateofBirthError("");
  //     } catch (error) {
  //       console.error("Invalid date:", error);
  //       setDateofBirthError("Invalid date selected");
  //     }
  //   } else {
  //     setDateofBirth(null);
  //     setDateofBirthError("Please select a valid date");
  //   }

  // }
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key, target } = event;
      const targetElement = target as HTMLInputElement;

      if (targetElement.type === 'number' && targetElement.name === 'year') {
        if (!/^[1-9]$/.test(key)) {
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const formatDateToString = (date: Date | null): string => {
    if (date === null) {
      return '';
    }
    return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  };

  const currentYear = new Date().getFullYear();
  const startOfCurrentYear = new Date(currentYear, 0, 1); // January 1st of the current year
  const endOfCurrentYear = new Date(currentYear, 11, 31); // December 31st of the current year

  // const handleChangeDateofBirthField = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   console.log(event.target.value);
  //   setDateofBirth(event.target.value);
  //   setDateofBirthError("");
  // };

  const handleChangeMobileNumberField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/; // Allow only numbers

    if (regex.test(inputValue)) {
      if (inputValue.length <= 12) {
        // Update the state with valid input within the max length
        setMobileNumber(inputValue);
        setMobileNumberError("");
      } else {
        // If the input exceeds the max length, set an error message
        setMobileNumberError("Mobile number must be exactly 12 digits.");
      }
    } else {
      // If the input contains invalid characters, set an error message
      setMobileNumberError("Please enter only numeric digits.");
    }
  };
  // const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(src);

  const [selectedImage, setSelectedImage] = useState<string>("");
  const [fileSelected, setFileSelected] = useState<any>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const fileRef1 = React.useRef<HTMLInputElement | null>(null);
  // const [selectedFiles, setSelectedFiles] = useState<File | null>();
  const [selectedFiles, setSelectedFiles] = useState<string | File | null>(null);


  // const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log(event?.target?.files?.[0].name)
  //   setSelectedFiles(event?.target?.files?.[0]);
  //   // setSelectedFileName(event?.target?.files?.[0].name);
  //   setProfilePhotoError("");
  //   let reader = new FileReader();
  //   // @ts-ignore
  //   reader.readAsDataURL(event?.target?.files?.[0]);
  //   reader.onload = (e) => {
  //     console.log(e.target?.result, "kkkkttt");
  //     setFileSelected(e.target?.result);
  //     //   setImageError("");
  //     //@ts-ignore
  //     // var eee4 = window.atob(e.target?.result)
  //     // console.log(eee4,'rrrrrrthds')
  //   };
  // };
  const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setSelectedFiles(file); // Only set the file if it is defined
      setProfilePhotoError("");

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        console.log(e.target?.result, "kkkkttt");
        setFileSelected(e.target?.result);
      };
    } else {
      setSelectedFiles(null); // Reset to null if no file is selected
    }
  };

  const handleSmallImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClearImage = () => {
    setSelectedImage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const resetFormFields = () => {
    setNameError("");
    setMailError("");
    setSelectedImage("");
    setdepartmentError("");
    // setFileSelected("");
    setExtension("");
    setReportingManagerError("");
    setDateofJoiningError("");
    setDesignationError("");
    setProfilePhotoError("");
    setEmployeeCodeError("");
    setDateofBirthError("");
    setRoleError("");
    setPersonalEmailError("");
    setMobileNumberError("");
    setLocation("");
    setSubLocation("");
    setAddress("");
    setDirectNo("");
    setProfilePhotoError("");
  };
  const resetAllErrorAlert = () => {
    setName("");
    setMail("");
    setdepartment("");
    setRole("");
    setDateofJoining(null);
    setDesignation("");
    setEmployeeCode("");
    setSelectedImage("");
    setProfilePhoto("");
    setDateofBirth(null);
    setFileSelected("");
    setReportingManager("");
    setMobileNumber("");
    setNameError("");
    setMailError("");
    setPersonalEmail("");
    setdepartmentError("");
    setReportingManagerError("");
    setRoleError("");
    setProfilePhotoError("");
    setDesignationError("");
    setPersonalEmailError("");
    setEmployeeCodeError("");
    setMobileNumberError("");
    setDateofJoiningError("");
    setDateofBirthError("");
    setProfilePhoto("");
    setProfilePhotoError("");
    setSelectedImage("");
  };
  const handleCancel = () => {
    handleDrawerClose();
    resetAllErrorAlert();
  };
  const validateFormm = () => {
    let formIsValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    if (Name === "") {
      setNameError("Name is required");
      formIsValid = false;
    }

    if (Mail === "") {
      setMailError("Email is required");
      formIsValid = false;
    } else if (!emailRegex.test(Mail)) {
      setMailError("Please enter a valid email address");
      formIsValid = false;
    } else {
      setMailError(""); // Clear the email error if input is valid
    }
    if (department === "") {
      setdepartmentError("department is required");
      formIsValid = false;
    }
    if (role === "") {
      setRoleError("Role is required");
      formIsValid = false;
    }
    if (reportingManager === "") {
      setReportingManagerError("Reporting Manager is required");
      formIsValid = false;
    }
    if (employeeCode === "") {
      setEmployeeCodeError("Employee Code is required");
      formIsValid = false;
    }
    if (designation === "") {
      setDesignationError("Designation is required");
      formIsValid = false;
    }
    // if (dateofJoining === null) {
    //   setDateofJoiningError("Date of Joining is required");
    //   formIsValid = false;
    // }
    if (dateofJoining === null) {
      setDateofJoiningError("Date of Joining is required");
      formIsValid = false;
    } else {
      const year = new Date(dateofJoining).getFullYear();
      if (isNaN(year) || year < 1000 || year > 9999) {
        setDateofJoiningError("Enter Valid Date");
        formIsValid = false;
      } else {
        setDateofJoiningError(""); // Clear error when eventDate is valid
      }
    }
    // if (dateOfBirth === null) {
    //   setDateofBirthError("Date of Birth is required");
    //   formIsValid = false;
    // }
    if (dateOfBirth === null) {
      setDateofBirthError("Date of Birth is required");
      formIsValid = false;
    } else {
      const year = new Date(dateOfBirth).getFullYear();
      if (isNaN(year) || year < 1000 || year > 9999) {
        setDateofBirthError("Enter Valid Date");
        formIsValid = false;
      } else {
        setDateofBirthError(""); // Clear error when eventDate is valid
      }
    }
    if (mobileNumber === "") {
      setMobileNumberError("Mobile Number is required");
      formIsValid = false;
    }
    // if (fileSelected === null) {
    //   setImageError("image is required");
    //   formIsValid = false;
    // }
    // if (fileSelected === null) {
    //   setProfilePhotoError("Image is required"); // Assuming you have an image error state
    //   formIsValid = false;
    // } else {
    //   setProfilePhotoError(""); // Clear the image error if an image is selected
    // }
    if (personalEmail === "") {
      setPersonalEmailError("Email is required");
      formIsValid = false;
    } else if (!emailRegex.test(personalEmail)) {
      setPersonalEmailError(
        "Please enter a valid email address ending with @gmail.com "
      );
      formIsValid = false;
    } else {
      setPersonalEmailError(""); // Clear the email error if input is valid
    }
    return {
      formIsValid,
    };
  };

  interface dataInterface {
    employeeName: any;
    reportingTo: any;
    role: any;
    department: string;
    mobileNumber: string;
    employeeCode: any;
    designation: string;
    personalEmail: string;
    dateOfJoining: any;
    dateOfBirth: any;
    dateAdded: string; // Adjusted to string
    employeeEmail: any;
    profileIcon: string;
  }

  const createFormData = (data: dataInterface) => {
    const formData = new FormData();
    // Append each property of ceoData individually
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append the file if selectedFiles is defined
    if (selectedFiles) {
      formData.append("file", selectedFiles);
    }
    return formData;
  };

  // // Helper function to convert data URL to Blob
  // const dataURLtoBlob = (dataUrl: string) => {
  //   const arr = dataUrl.split(',');
  //   const mime = arr[0].match(/:(.*?);/)?.[1] || 'image/jpeg';
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new Blob([u8arr], { type: mime });
  // };

  // const handleAddProfile = () => {

  //   // Form validations
  //   const { formIsValid } = validateForm();
  //   if (!formIsValid) {
  //     // There are validation errors, don't submit the form
  //     return;
  //   }

  //   const newRow = {
  //     employeeName: Name,
  //     role: role,
  //     department: department,
  //     reportingTo: reportingManager,
  //     dateOfJoining: dateofJoining,
  //     employeeCode: employeeCode,
  //     dateOfBirth: dateOfBirth,
  //     designation: designation,
  //     dateAdded: new Date()?.toDateString(),
  //     mobileNumber: mobileNumber,
  //     personalEmail: personalEmail,
  //     employeeEmail: Mail,
  //     profileIcon: fileSelected || defaultProfileIcon,
  //   };

  //   const formData = createFormData(newRow); // Provide selectedImage
  //   sendEmployeeData(formData)
  //     .then(() => {
  //       refetch();
  //     })
  //     .catch((error) => {
  //       console.error("Error while sending employee data:", error);
  //     });
  //   handleClick3("Data submitted successfully")();
  //   resetFormFields();
  //   resetAllErrorAlert();
  //   handleDrawerClose();
  // };
  const validateForm = () => {
    let formIsValid = true;
    const emailRegex = /^[^\s@]+@(gmail\.com|\w+(\.\w+){1,2})$/;
    const currentYear = new Date().getFullYear();

    // Name validation
    if (Name?.trim() === "") {
      setNameError("Name is required");
      formIsValid = false;
      console.log("Name validation failed");
    }

    // Mail validation
    if (Mail?.trim() === "") {
      setMailError("Email is required");
      formIsValid = false;
      console.log("Mail validation failed - email is required");
    } else if (!emailRegex.test(Mail)) {
      setMailError("Please enter a valid email address ending with @gmail.com");
      formIsValid = false;
      console.log("Mail validation failed - invalid email format");
    }

    // Department validation
    // if (department?.trim() === "") {
    //   setdepartmentError("Department is required");
    //   formIsValid = false;
    //   console.log("Department validation failed");
    // }

    // Role validation
    // if (role?.trim() === "") {
    //   setRoleError("Role is required");
    //   formIsValid = false;
    //   console.log("Role validation failed");
    // }

    // Reporting Manager validation
    // if (reportingManager?.trim() === "") {
    //   setReportingManagerError("Reporting Manager is required");
    //   formIsValid = false;
    //   console.log("Reporting Manager validation failed");
    // }

     if (extension?.trim() === "") {
      setExtensionError("Extension is required");
      formIsValid = false;
      console.log("Extension validation failed");
    }

    // Employee Code validation
    if (employeeCode?.trim() === "") {
      setEmployeeCodeError("Employee Code is required");
      formIsValid = false;
      console.log("Employee Code validation failed");
    }

    // Designation validation
    if (designation?.trim() === "") {
      setDesignationError("Designation is required");
      formIsValid = false;
      console.log("Designation validation failed");
    }
    // Old code
    // Date of Joining validation
    // if (dateofJoining === null) {
    //   setDateofJoiningError("Date of Joining is required");
    //   formIsValid = false;
    //   console.log("Date of Joining validation failed");
    // }

    // Date of Birth validation
    // if (dateOfBirth === null) {
    //   setDateofBirthError("Date of Birth is required");
    //   formIsValid = false;
    //   console.log("Date of Birth validation failed");
    // }
    //working code
    // if (!dateofJoining) {
    //   setDateofJoiningError("Date of Joining is required");
    //   formIsValid = false;
    //   console.log("Date of Joining validation failed");
    // } else {
    //   const today = new Date();
    //   const selectedDate = new Date(dateofJoining);
    
    //   if (isNaN(selectedDate.getTime())) {
    //     setDateofJoiningError("Invalid Date of Joining");
    //     formIsValid = false;
    //     console.log("Date of Joining validation failed");
    //   } else if (selectedDate > today) {
    //     setDateofJoiningError("Date of Joining cannot be in the future");
    //     formIsValid = false;
    //     console.log("Date of Joining validation failed");
    //   } else {
    //     setDateofJoiningError(""); // Clear any previous error
    //   }
    // }
    
    // New Code
    // if (!dateofJoining) {
    //   setDateofJoiningError("Date of Joining is required");
    //   formIsValid = false;
    //   console.log("Date of Joining validation failed");
    // } else {
    //   const year = new Date(dateofJoining).getFullYear();
    //   if (isNaN(year) || year !== currentYear) {
    //     setDateofJoiningError(`Date of Joining must be in the current year (${currentYear})`);
    //     formIsValid = false;
    //     console.log("Date of Joining validation failed");
    //   } else {
    //     setDateofJoiningError(""); // Clear any previous error
    //   }
    // }
    //working code
    // if (!dateOfBirth) {
    //   setDateofBirthError("Date of Birth is required");
    //   formIsValid = false;
    //   console.log("Date of Birth validation failed");
    // } else {
    //   const year = new Date(dateOfBirth).getFullYear();
    //   if (isNaN(year) || year === currentYear || year < 1000 || year > 9999) {
    //     setDateofBirthError("Date of Birth must be a valid four-digit year and cannot be the current year");
    //     formIsValid = false;
    //     console.log("Date of Birth validation failed");
    //   } else {
    //     setDateofBirthError(""); // Clear any previous error
    //   }
    // }

    // Mobile Number validation
    // if (mobileNumber?.trim() === "") {
    //   setMobileNumberError("Mobile Number is required");
    //   formIsValid = false;
    //   console.log("Mobile Number validation failed");
    // }

    // // Personal Email validation
    // if (personalEmail.trim() === "") {
    //   setPersonalEmailError("Email is required");
    //   formIsValid = false;
    //   console.log("Personal Email validation failed - email is required");
    // } else if (!emailRegex.test(personalEmail)) {
    //   setPersonalEmailError("Please enter a valid email address ending with @gmail.com");
    //   formIsValid = false;
    //   console.log("Personal Email validation failed - invalid email format");
    // }

    console.log('Form submission initiated', formIsValid);

    // If the form is not valid, do not proceed
    return {
      formIsValid,
    };
  };
  const handleAddProfile = () => {
    resetFormFields();
    // Form validations
    // Form validations
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      // There are validation errors, don't submit the form
      return;
    }
    console.log("Form is valid, proceeding with submission.");

    // Form is valid, proceed with form submission
    const newRow = {
      employeeName: Name,
      role: role,
      department: department,
      reportingTo: reportingManager,
      dateOfJoining: dateofJoining,
      employeeCode: employeeCode,
      dateOfBirth: dateOfBirth,
      designation: designation,
      dateAdded: new Date().toDateString(),
      mobileNumber: mobileNumber,
      personalEmail: personalEmail,
      employeeEmail: Mail,
      Ext:extension,
      Location:location,
      SubLocation:subLocation,
      address:address,
      DirectNo:directNo,
      profileIcon: fileSelected || defaultProfileIcon,
    };

    //   const formData = createFormData(newRow); // Create FormData with the employee data

    //   sendEmployeeData(formData)
    //     .then(() => {
    //       refetch(); // Re-fetch the data after successful submission
    //       handleClick3("Data submitted successfully")(); // Show success message
    //       resetFormFields(); // Reset the form fields after submission
    //       resetAllErrorAlert(); // Reset all error states
    //       handleDrawerClose(); // Close the drawer or modal
    //     })
    //     .catch((error) => {
    //       console.error("Error while sending employee data:", error);
    //     });
    // };
    const formData = createFormData(newRow); // Create FormData with the employee data

    if (!isEditMode) {
      // Adding new employee
      sendEmployeeData(formData)
        .then(() => {
          refetch(); // Re-fetch the data after successful submission
          handleClick3("Data submitted successfully")(); // Show success message
          resetFormFields(); // Reset the form fields after submission
          resetAllErrorAlert(); // Reset all error states
          handleDrawerClose(); // Close the drawer or modal
        })
        .catch((error) => {
          console.error("Error while sending employee data:", error);
        });
    } else {
      // Updating existing employee
      formData.append("EmployeeId", dataId);
      // formData.append("previousFile", fileSelected); // Uncomment if needed for updating the previous file

      updateEmployeeData(formData)
        .then(() => {
          refetch(); // Re-fetch the data after successful update
          handleClick3("Data updated successfully")(); // Show success message
          resetFormFields(); // Reset the form fields after submission
          resetAllErrorAlert(); // Reset all error states
          handleDrawerClose(); // Close the drawer or modal
          setIsEditMode(false); // Reset edit mode state after updating
        })
        .catch((error: any) => {
          console.error("Error while updating employee data:", error);
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    resetAllErrorAlert();
  };

  const getRowClassName = () => {
    return "EmployeeMastertablecellcontent";
  };

  const getCellClassName = () => {
    return "EmployeeMastertablecellcontent";
  };
  const handleAddUserClick = () => {
    setIsDrawerOpen(true);
    handleClose();
  };

  const handleAddMultipleUsersClick = () => {
    // setDialogOpen(true); // Open the dialog
    setFileDialogOpen(true);
  };
  // onClick={() =>
  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog
  };

  // const handleFileAccepted = (file: File) => {
  //   // Handle the accepted file, you can process it here
  //   console.log('Accepted file:', file);
  // };
  // const [searchTerm, setSearchTerm] = useState("");

  // Function to handle search term change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };
  // const filteredResults = rows.filter(
  //   (item: {
  //     employeeCode: any;
  //     employeeName: any;
  //     department: any;
  //     dateAdded: any;
  //     mobileNumber: any;
  //     role: any;
  //     item: any;
  //     name: string;
  //     email: string;
  //   }) =>
  //     item.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  //     item.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  //     item.department?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  //     item.dateAdded?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  //     item.mobileNumber?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  //     item.role?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  //     item.employeeName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  //     item.employeeCode?.toLowerCase().includes(searchTerm?.toLowerCase())
  //   // ||
  //   // item.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase())
  //   // ||
  //   // item.email.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  // const [selectedImage, setSelectedImage] = useState<string>("");

  // const handleImageChange = (file: File) => {
  //   // Handle the file here, for example, you can set the selected image URL
  //   const imageUrl = URL.createObjectURL(file);
  //   setSelectedImage(imageUrl);
  // };
  // const handleAlertYes = () => {
  //   deleteEmployeeData(dataId);
  //   setAlertActivation(false);
  //   refetch();
  //   //snackBar alert
  //   setSnackbarOpen(true);
  //   setSnackbarMessage("Data has been deleted successfully");
  // };

  const handleAlertYes = () => {
    console.log("Deleting employee with ID:", dataId); // Log for debugging
    if (dataId) {
      deleteEmployeeData(dataId)
        .unwrap()
        .then(() => {
          setAlertActivation(false);
          setRows((prevRows) => prevRows.filter(row => row.id !== dataId)); // Remove the employee from the state
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been deleted successfully");
        })
        .catch((error) => {
          setSnackbarOpen(true);
          setSnackbarMessage("Failed to delete data");
        });
    }
  };




  console.log(dataId, "dataId")

  const handleAlertCancel = () => {
    setAlertActivation(false);
  };
  const handleAlertYesForCancel = () => {
    handleDrawerClose();
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleAlertNoCancel = () => {
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  // exporting in excel
  console.log("rowss", rows)
  const dddd = rows?.map((j: any) => {
    const formattedDateOfJoining = j?.dateOfJoining
      ? new Date(j.dateOfJoining).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      : '';
    const formattedDateOfBirth = j?.dateOfBirth
      ? new Date(j.dateOfBirth).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      : '';
    return {
      Ecode: j?.employeeCode,
      Employename: j?.name,
      Employeemail: j?.email,
      Designation: j?.designation,
      MobileNumber: j?.mobileNumber,
      Department: j?.department,
      DateOfJoining: formattedDateOfJoining,
      DateOfBirth: formattedDateOfBirth,
      // Role:j?.role,
      // Department:j?.department,
      // ReportingManager:j?.reportingManager,
      // DateOfJoining:j?.dateOfJoining,
      // DateOfBirth:j?.dateOfBirth,
      // MobileNumber:j?.mobileNumber

      //   Position: j.position_long_description,
      //   Grade: j.grade,
      //   SupervisoryRole: j.isSupervisor,
      //   Function: j?.function,
      //   AppraiserName: j?.appraiser_name,
      //   ReviewerName: j?.reviewer_name,
      //   NormalizerName: j?.normalizer_name,
      //   Section: j.section,
      //   ServiceReferenceDate: j.service_reference_date,
      //   SubSection: j["sub section"],
      //   Division: j.division,
      //   ManagerPosition: j?.manager_position,
      //   WorkLocation: j?.work_location,
      //   AppraiserCode: j?.appraiser_code,
      //   ReviewerCode: j?.reviewer_code,
      //   NormalizerCode: j?.normalizer_code
    };
  });
  console.log(dddd, "currentPageRows", rows);
  const handleExport = () => {
    // var wb = XLSX.utils.book_new(),
    //     ws = XLSX.utils.json_to_sheet(dddd);
    // XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    // XLSX.writeFile(wb, "MyExcel.xlsx");

    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dddd);
    // Calculate the maximum width for each column
    const colWidths = dddd.reduce((acc: number[], row: any) => {
      Object.keys(row).forEach((key, i) => {
        const cellValue = row[key]?.toString() || "";
        const cellWidth = cellValue.length;
        if (!acc[i] || cellWidth > acc[i]) {
          acc[i] = cellWidth;
        }
      });
      return acc;
    }, []);
    // Adjust column widths to fit the content
    ws["!cols"] = colWidths.map((width: any) => ({ wch: width }));
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    // Write the workbook to a file
    XLSX.writeFile(wb, "MyExcel.xlsx");
  };

  let content;

  if (isLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress style={{ color: "#009BAD" }} />
      </div>
    );
  } else if (isSuccess) {
    content = (
      <div
        style={{
          display: "flex",
          height: "85%",
          marginLeft: "15px",
          marginRight: "15px",
        }}
      >
        {/* <Box sx={{ flexGrow: 1, width: "100%" }}>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },
              border: "none !important",
            }}
            //  className={classes.DataGrid}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
            disableDensitySelector
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector={true}
          />
        </Box> */}
        {/* <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleAlertYes}
          handleAlertClose={handleAlertCancel}
        >
          {alertContent}
        </AlertYesNo> */}
        <AlertYesNo
          isAlertOpen={alertActivationForCancel}
          handleAlertYes={handleAlertYesForCancel}
          handleAlertClose={handleAlertNoCancel}
        >
          {alertContent}
        </AlertYesNo>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#e0001b !important",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "20px",
              marginRight: "124px",
              fontSize: "14px ! important",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
      </div>
    );
  }
  return (
    <div style={{ padding: "20px" }}>
      <AlertYesNo
        isAlertOpen={alertActivation}
        handleAlertYes={handleAlertYes}
        handleAlertClose={handleAlertCancel}
      >
        {alertContent}
      </AlertYesNo>
      <div>
        <div>
          {/* Search bar outside the box */}
          {/* <TextField
            variant="outlined"
            placeholder="Search something..."
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    style={{
                      width: "23px",
                      height: "23px",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "8px",
                height: "36px",
                width: "400px",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
          /> */}
        </div>
        <div>
          <Stack className="Employeemaster-Stack">
            <div>
              <div className="content11">Users</div>
              <div>
                <div className="content12">
                  You can add users and view particular user details
                </div>
              </div>
            </div>
            {/* <div>
              <Stack direction="row" spacing={0}> */}
                {/* <Button
                  className="subdivContainer"
                  sx={{
                    backgroundColor:
                      activeButton === "list" ? "#009BAD" : "#ffffff",
                    color: activeButton === "list" ? "white" : "#009BAD",
                    "&:hover": {
                      backgroundColor:
                        activeButton === "list" ? "#009BAD" : "#ffffff",
                      color: activeButton === "list" ? "white" : "#009BAD",
                    },
                  }}
                  onClick={() => handleButtonClick("list")}
                >
                  <img
                    src={Vector}
                    alt="Vector Icon"
                    className="vector-icon"
                    style={{
                      width: "15.58px",
                      height: "15.57px",
                      top: "4.21px",
                      left: "4.21px",
                      color: "#FFFFFF",
                    }}
                  />
                  <span
                    className="list11"
                    style={{ textTransform: "capitalize" }}
                  >
                    List
                  </span>
                </Button> */}
                {/* <Button
                  sx={{
                    backgroundColor:
                      activeButton === "dashboard" ? "#009BAD" : "#ffffff",
                    opacity: "0.6",
                    border: "1px solid #009BAD",
                    height: "35px",
                    borderRadius: "6px",
                    "&:hover": {
                      backgroundColor:
                        activeButton === "dashboard" ? "#009BAD" : "#ffffff",
                      opacity: "0.6",
                      border: "1px solid #009BAD",
                      height: "36px",
                      borderRadius: "6px",
                    },
                  }}
                  onClick={() => handleButtonClick("dashboard")}
                >
                  <img
                    src={DashboardIcon}
                    alt="Vector Icon"
                    className="vector-icon"
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </Button> */}
              {/* </Stack>
            </div> */}
            {/* <div> */}
              {/* <div>
                <TextField
                  variant="outlined"
                  placeholder="Search something..."
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={SearchIcon}
                          alt="Search Icon"
                          style={{
                            width: "23px",
                            height: "23px",
                          }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      borderRadius: "8px",
                      height: "36px",
                      width: "715px",
                    },
                  }}
                />
              </div> */}
              {/* <div> */}
                {/* Render filtered results */}
                {/* <ul>
                    {filteredResults.map((item: { id: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                  </ul> */}
              {/* </div> */}
            {/* </div> */}
             <div>
                  <SearchBar
                    searchTerm={searchTerm}
                    onSearchChange={handleSearchChange}
                    onClearSearch={handleClearSearch} // Add this line
                  />
                </div>
            <div>
              <Stack direction="row" spacing={2}>
               
                <div
                  onClick={handleExport}
                >
                  <Tooltip placement="bottom-end" title="Click to download Export">
                    <img
                      src={Excel}
                      alt="Vector Icon"
                      className="vector-iconnew"
                      style={{ gap: "5px" }}
                    />
                  </Tooltip>
                </div>

                <Button style={{ textTransform: 'capitalize' }}
                  className="Adduser-Button2" onClick={handleOpenDialog1}>Bulk Upload Images</Button>
                <FileDialog12
                  open={dialogOpen1}
                  onClose={handleCloseDialog1}
                  onFileAccepted={handleFileAccepted1}
                  refreshTableData={refreshTableData}
                />

                {/* <div>
                  <input
                    type="file"
                    accept=".zip"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="upload-zip"
                  />
                  <label htmlFor="upload-zip">
                    <Button
                      sx={{ textTransform: "capitalize", display: "flex" }}
                      className="Adduser-Button"
                      component="span"
                      onClick={() => document.getElementById("upload-zip")?.click()}
                    >
                      <img
                        src={person}
                        alt="Vector Icon"
                        className="vector-icon"
                      />
                      Image
                    </Button>
                  </label>
                  {selectedFile && (
                    <Button
                      onClick={handleUpload}
                      sx={{ textTransform: "capitalize", display: "flex", marginTop: 2 }}
                    >
                      Upload
                    </Button>
                  )}
                </div> */}
                <Button
                  sx={{ textTransform: "capitalize", display: "flex" }}
                  className="Adduser-Button"
                  onClick={handleClick}
                // sx={{ backgroundColor: "#009BAD", color: "white", }}
                // variant="outlined" startIcon={<person />}
                >
                  <img
                    src={person}
                    alt="Vector Icon"
                    className="vector-icon"
                    style={{}}
                  />
                  Add User
                </Button>

                {/* <Button
                  sx={{ textTransform: "capitalize", display: "flex" }}
                  className="Export-Button"
                  onClick={handleExport}
                // sx={{ backgroundColor: "#009BAD", color: "white", }}
                // variant="outlined" startIcon={<person />}
                >
                  <img
                    src={Excel}
                    alt="Vector Icon"
                    className="vector-icon"
                    style={{ gap: "5px" }}
                  />
                  Export
                </Button> */}
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <div style={{ borderRadius: "10px !important" }}>
                    <MenuItem
                      onClick={handleAddUserClick}
                      sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}
                    >
                      <img
                        src={AddUser}
                        alt="Vector Icon"
                        className="vector-icon"
                        style={{}}
                      />
                      Add User
                    </MenuItem>
                  </div>
                  <MenuItem
                    onClick={handleAddMultipleUsersClick}
                    sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}
                  >
                    <img
                      src={AddMultipleUsersIcon}
                      alt="Vector Icon"
                      className="vector-icon"
                      style={{}}
                    />
                    Add Multiple Users
                  </MenuItem>
                  <Dialog open={fileDialogOpen} onClose={handleCloseDialog}>
                    <FileDialog
                      open={fileDialogOpen}
                      onClose={() => setFileDialogOpen(false)}
                      onFileAccepted={handleFileAccepted}
                    />
                  </Dialog>
                </Menu>

                <Drawer
                  anchor="right"
                  open={isDrawerOpen}
                  // onClose={handleDrawerClose}
                  className="drawer"
                >
                  {/* <div
                    style={{
                      backgroundColor: "#e0001b",
                      color: "white",
                      height: "56px",
                    }}
                  >
                    <Typography variant="h5" className="addUser">
                      <img
                        src={small}
                        alt="Vector Icon"
                        className="vector-icon"
                        style={{
                          height: "36px",
                          width: "19px",
                        }}
                      />
                      Add User
                    </Typography>
                  </div> */}
                    <div className="DrawerHeaderBoxEM">
                  {/* <div className="addUser"> */}
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <img
                        style={{ height: "20px", width: "17px" }}
                        src={small}
                        alt="Profil Icon "
                      />
                      <div className="DrawerTitle">Add User</div>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <div>
                        <Tooltip placement="top-start" title="Click to Add Profile">
                          <PublishIcon
                            sx={{
                              textTransform: "capitalize",
                              backgroundColor: "var(--blackprimary)",
                              color: "white !important",
                              height: "17px",
                              "&:hover": {
                                backgroundColor: "var(--blackprimary)",
                              },
                              // display: isButtonDisabled ? "none" : "inline-flex",
                              cursor: "pointer",
                            }}
                            onClick={handleAddProfile}
                            className={"PrimaryBlueButton"}
                          />
                        </Tooltip>

                      </div>
                      <div>
                        <Tooltip placement="top-start" title="Click to Close">
                          <CloseRoundedIcon
                            onClick={handleCancel}
                            style={{
                              cursor: "pointer",
                              color: "whitesmoke",
                              padding: "5px",
                              borderRadius: "50%",
                              height: "16px",
                              transition: "background-color 0.3s",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor =
                                "rgba(255, 255, 255, 0.2)";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = "transparent";
                            }}
                          />
                        </Tooltip>
                      </div>
                    </Stack>
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      width: "380px",
                      height: "auto",
                      marginTop: "50px"
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <div className="div11">
                        <div
                          style={{
                            position: "relative",
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={fileSelected || BigDefault}
                            alt="Big Icon"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleUploadMedia}
                          />
                        </div>
                        <img
                          src={Small2}
                          alt="Small Icon"
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            left: "205px",
                            top: "155px",
                            transform: "translate(50%, 50%)",
                          }}
                          onClick={() => {
                            handleSmallImageClick();
                            handleClearImage(); // Call function to clear the selected image
                          }}
                        />
                      </div>
                      {/* <ImgUpload
                        onChange={handleImageChange}
                        src={selectedImage}
                      /> */}
                      <div className="label">
                        <div className="subHeading">
                          Name
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={Name}
                        style={{ width: "100%" }}
                        onChange={handleChangeTitleField}
                        placeholder="Enter Name"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{NameError}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Work Email
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={Mail}
                        style={{ width: "100%" }}
                        onChange={handleChangeMailField}
                        placeholder="Enter Work Email"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{MailError}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                        Extension
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={extension}
                        style={{ width: "100%" }}
                        onChange={handleChangeExtensionField}
                        placeholder="Enter Extension"
                        autoComplete="off"
                        inputProps={{
                          style: {
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />{" "}
                      <div className="error">{extensionError}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Employee Code
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={employeeCode}
                        style={{ width: "100%" }}
                        onChange={handleChangeEmployeeCodeField}
                        placeholder="Enter Employee Code"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{employeeCodeError}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Designation
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={designation}
                        style={{ width: "100%" }}
                        onChange={handleChangeDesignationField}
                        placeholder="Enter Designation"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{designationError}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Role
                          {/* <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          /> */}
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={role}
                        style={{ width: "100%" }}
                        onChange={handleChangeRoleField}
                        placeholder="Enter Role"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{roleError}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Department
                          {/* <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          /> */}
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={department}
                        style={{ width: "100%" }}
                        onChange={handleChangedepartmentField}
                        placeholder="Enter Department"
                        autoComplete="off"
                        inputProps={{
                          style: {
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />{" "}
                      <div className="error">{departmentError}</div>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Reporting Manager
                          {/* <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          /> */}
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={reportingManager}
                        style={{ width: "100%" }}
                        onChange={handleChangeReportingManagerField}
                        placeholder="Enter Reporting Manager"
                        autoComplete="off"
                        inputProps={{
                          style: {
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />{" "}
                      <div className="error">{reportingManagerError}</div>
                    </div>

                    {/* <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Date of Joining
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={dateofJoining}
                        type="date"
                        style={{ width: "100%" }}
                        onChange={handleChangeDateofJoiningField}
                        // placeholder="Enter Role here"
                      />{" "}
                      <div className="error">{dateofJoiningError}</div>
                    </div> */}

                    <div style={{ marginBottom: "10px", flex: 1 }}>
                      <div className="label">
                        <img
                          src={calenderIcon}
                          alt=""
                          className="subHeadingimg"
                        />
                        <div className="subHeading">
                          Date of Joining
                          {/* <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          /> */}
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={dateofJoining}
                          // minDate={startOfCurrentYear}
                          // maxDate={endOfCurrentYear}
                          maxDate={new Date()}
                          onChange={handleChangeDateofJoiningField}
                          className="calendarMain"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              autoComplete: "off",
                              inputProps: {
                                name: 'year', // Add name attribute for identification
                                style: { height: '1px' },
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error">{dateofJoiningError}</div>
                    </div>

                    {/* <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Date of Birth
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={dateOfBirth}
                        type="date"
                        style={{ width: "100%" }}
                        onChange={handleChangeDateofBirthField}
                      // placeholder="Enter Role here"
                      />{" "}
                      <div className="error">{dateOfBirthError}</div>
                    </div> */}

                    <div style={{ marginBottom: "10px", flex: 1 }}>
                      <div className="label">
                        <img
                          src={calenderIcon}
                          alt=""
                          className="subHeadingimg"
                        />
                        <div className="subHeading">
                          Date of Birth
                          {/* <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          /> */}
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={dateOfBirth}
                          onChange={handleChangeDateofBirthField}

                          className="calendarMain"

                          // minDate={startOfCurrentYear}
                          // maxDate={endOfCurrentYear}

                          slotProps={{
                            textField: {
                              fullWidth: true,
                              autoComplete: "off",
                              inputProps: {
                                name: 'year', // Add name attribute for identification
                                style: { height: '1px' },
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error">{dateOfBirthError}</div>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                          Mobile Number
                          {/* <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          /> */}
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={mobileNumber}
                        style={{ width: "100%" }}
                        onChange={handleChangeMobileNumberField}
                        placeholder="Enter Mobile Number"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{mobileNumberError}</div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                        Location
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={location}
                        style={{ width: "100%" }}
                        onChange={handleChangeLocationField}
                        placeholder="Enter Location"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{locationError}</div>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                         Sub-Location
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={subLocation}
                        style={{ width: "100%" }}
                        onChange={handleChangeSubLocationField}
                        placeholder="Enter Sub-Location"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{subLocationError}</div>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                         Address
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={address}
                        style={{ width: "100%" }}
                        onChange={handleChangeAddressField}
                        placeholder="Enter Sub-Location"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{addressError}</div>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <div className="label">
                        <div className="subHeading">
                         DirectNo
                          <img
                            src={Asterisk}
                            alt="..."
                            style={{ marginBottom: "5px" }}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={directNo}
                        style={{ width: "100%" }}
                        onChange={handleChangeDirectNoField}
                        placeholder="Enter Sub-Location"
                        autoComplete="off"
                      />{" "}
                      <div className="error">{directNoError}</div>
                    </div>
                    {/* <div
                      style={{ marginTop: "208px", marginLeft: "326px" }}
                    ></div> */}
                    {/* <div className="AddCancel-Button">
                      <Stack direction="row" spacing={2}>
                        <Button
                          // variant="contained"
                          onClick={handleCancel}
                          className="Cancel1"
                          style={{
                            backgroundColor: "rgba(230, 230, 230, 1)",
                            color: "rgba(125, 125, 125, 1)",
                            textTransform: "none",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          // variant="contained"
                          onClick={handleAddProfile}
                          className="Add1"
                          style={{
                            backgroundColor: "var(--redprimary)",
                            color: "rgba(255, 255, 255, 1)",
                            textTransform: "none",
                          }}
                        >
                          Add Profile
                        </Button>
                      </Stack>
                    </div> */}
                  </div>
                </Drawer>
                {/* <div className="divContainer2">
                  <Button
                    sx={{
                      backgroundColor: "white",
                      color: "#009BAD",
                      borderColor: "red" ,
                      border: "1px solid #009BAD",
                      borderRadius: "5px",
                      height: "36px",
                    }}
                    className="buttonWithBorder"
                  >
                    <img
                      src={TuneIcon}
                      alt="Vector Icon"
                      className="vector-icon"
                      style={{
                        width: "24px",
                        height: "18px",
                      }}
                    />
                    <span style={{ textTransform: "capitalize" }}>Filter</span>
                  </Button>
                </div> */}
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
      <div className="Action-menu">
        <Menu
          id="basic-menu"
          anchorEl={anchorE2}
          open={open}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}
            onClick={handleDisable}
          >
            <img src={AddUser} alt="Vector Icon" className="vector-icon" />
            Enable User
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}
            onClick={handleDisable}
          >
            <img src={AddUser} alt="Vector Icon" className="vector-icon" />
            Disable User
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", color: "rgba(125, 125, 125, 1)" }}
            onClick={handleRemove}
          >
            <img src={AddUser} alt="Vector Icon" className="vector-icon" />
            Remove User
          </MenuItem>
        </Menu>
      </div>

      <div className="data-table">
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#D0D0D0 !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
            {
              outline: "none",
            },
            border: "none !important",
          }}
          rows={paginatedRows}
          columns={columns}
          disableDensitySelector
          // rowCount={filteredRows.length}
          // paginationModel={{ page: page - 1, pageSize }}
          // onPaginationModelChange={(model) => {
          //   setPage(model.page + 1);
          //   setPageSize(model.pageSize);
          // }}
          rowCount={filteredRows.length} // Update to ensure correct count
          paginationMode="server"
          onPaginationModelChange={(model) => {
            setPage(model.page + 1);
            setPageSize(model.pageSize);
          }}
          disableColumnFilter
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnSelector={true}
          hideFooterPagination={true}
          getRowClassName={getRowClassName}
          getCellClassName={getCellClassName}
          className="custom-data-grid"
          style={{
            color: "rgba(30, 30, 30, 1)!important",
            boxShadow: "20px !important",
          }}
        />
      </div>
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {/* <Typography
            variant="body2"
            sx={{ marginRight: "10px", color: "gray" }}
          >
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              rows.length
            )} of ${rows.length}`}
          </Typography> */}
          <Typography
            variant="body2"
            sx={{ marginRight: "10px", color: "gray" }}
          >
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              filteredRows.length
            )} of ${filteredRows.length}`}
          </Typography>
          <Pagination
            // count={Math.ceil(rows.length / pageSize)}
            count={Math.ceil(filteredRows.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#666",
                "&.Mui-selected": {
                  backgroundColor: "var(--blackprimary)",
                  color: "white",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)", // Ensures the color stays the same on hover
                  },
                },
              },
              "& .MuiIconButton-root": {
                color: "#666",
              },
            }}
          />
          {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      filteredRows
                        .map((row) => {
                          const value = row[filterField as keyof typeof row];
                          if (filterField === "expiresOn" && value) {
                            const date = new Date(value);
                            if (isValid(date)) {
                              return format(date, 'yyyy-MM-dd');
                            }
                          }
                          return value;
                        })
                        .filter(
                          (value) =>
                            value !== null &&
                            value !== undefined &&
                            value !== "" &&
                            value !== "NaN-aN-aN"
                        )
                    )
                  ).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
                  : []
              }
              selectedOptions={filterSelectedOptions[filterField || ""] || []}
              hideCheckboxes={searchTerm !== ""}
            />
          )}
        </div>

        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
          <div>
            <Button
              onClick={(event) => handlePageChange(event, currentPage - 1)}
              disabled={currentPage === 1}
              style={{ color: "rgba(94, 94, 94, 1)", fontSize: "10px" }}
            >
              <img
                className="remoicon-style1"
                src={PreveiwIcon}
                alt="Remo Digital"
              />
              Previous
            </Button>
          </div>
          <Pagination
            count={Math.ceil(rows.length / pageSize)} 
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            hidePrevButton 
            hideNextButton 
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: '#099BAD', 
                color: '#ffffff', 
                position: 'relative', 
              },
              '& .MuiPaginationItem-root.Mui-selected::after': {
                content: '""',
                position: 'absolute',
                bottom: '-7px', 
                left: 0,
                width: '100%',
                height: '3px',
                backgroundColor: '#099BAD', 
                borderRadius: '2px', 
              },
              '& .MuiPaginationItem-root.Mui-selected:hover': {
                backgroundColor: '#099BAD', 
              },
            }}
          />
          <div>
            <Button
              onClick={(event) => handlePageChange(event, currentPage + 1)}
              disabled={currentPage === Math.ceil(rows?.length / itemsPerPage)}
              style={{ color: "rgba(94, 94, 94, 1)", fontSize: "10px" }}
            >
              Next
              <img
                className="remoicon-style1"
                src={nextIcon}
                alt="Remo Digital"
              />
            </Button>
          </div>
        </div> */}

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          <Button
            onClick={() => handlePageChange(null, currentPage - 1)}
            disabled={currentPage === 1}
            style={{ color: "rgba(94, 94, 94, 1)", fontSize: "12px" }}
          >
            <img
              className="remoicon-style1"
              src={PreveiwIcon}
              alt="Remo Digital"
              // onClick={() => navigate("/")}
            />
            Previous
          </Button>
          <Pagination
            count={Math.ceil(rows.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            siblingCount={0} // Set to 0 to hide the arrows
            sx={{
              "& .Mui-selected": {
                color: "rgba(94, 94, 94, 1)", // Change the text color
                borderColor: "rgba(255, 255, 255, 1)", // Change the border color
                backgroundColor: "#009BAD", // Change the background color
              },
            }}
          />
          <Button
            onClick={() => handlePageChange(null, currentPage + 1)}
            disabled={currentPage === Math.ceil(rows.length / itemsPerPage)}
            style={{ color: "rgba(94, 94, 94, 1)", fontSize: "12px" }}
          >
            Next
            <img
              className="remoicon-style1"
              src={nextIcon}
              alt="Remo Digital"
              // onClick={() => navigate("/")}
            />
          </Button>
        </Box> */}

        {/* Pagination */}
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          <Button>Preveiw</Button>
          <Pagination count={5} variant="outlined" shape="rounded" />
          <Button>Next</Button>
        </Box> */}
        {/* <Drawer
          anchor="right"
          open={isProfileDrawerOpen}
          onClose={handleCloseProfileDrawer}
          classes={{
            paper: "newPosOfDialog",
          }}
          style={{
            margin: "6px !important",
            height: "100%",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="employeeBreadcrumb">
              <div className="employeeBreadcrumbText">Employee Master</div>
              <span className="employeeBreadcrumbRowicon">
                <NavigateNextIcon />
              </span>
              <span className="employeeBreadcrumb2">Profile</span>
            </div>
            <div>
              <Button onClick={handleCloseProfileDrawer}>
                <Tooltip title="Close" placement="top">
                  <ClearIcon
                    style={{
                      width: "20px",
                      color: "#a7a7a7",
                      marginTop: "-5px",
                    }}
                  />
                </Tooltip>
              </Button>
            </div>
          </div>
          <Divider />
          <div
            style={{
              marginTop: "15px",
            }}
          >
            {selectedRow && (
              <>
                <div
                  style={{
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={
                      employeeData?.profileIcon ? employeeData.profileIcon : man
                    }
                    alt="Profile"
                    style={{
                      width: "40%",
                      height: "140px",
                      borderRadius: "10px",
                    }}
                  />
                  <div className="headTextNew">{selectedRow.name}</div>
                </div>
                <Typography
                  style={{
                    fontSize: "23px",
                    fontWeight: "bold !important",
                    paddingBottom: "20px",
                  }}
                >
                 <span
                    style={{
                      color: "#FFDF00",
                      borderBottom: "2px solid #FFDF00",
                    }}
                  >
                    B
                  </span>
                  <span
                    style={{
                      color: "#3e3e3e !important",
                      borderBottom: " 2px solid #FFDF00",
                    }}
                  >
                    asic Details
                  </span>
                </Typography>
              </>
            )}
          </div>
          <Box sx={{ flexGrow: 1 }} style={{ paddingBottom: "30px" }}>
            {selectedRow && (
              <Stack spacing={2} className="basic">
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Employee Name</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                        {selectedRow.name}
                      </span>
                    </Grid>

                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Extension Number</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                        {selectedRow?.Ext ? selectedRow.Ext : "-"}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Designation</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                      {selectedRow.designation}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Email</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                      {selectedRow.email}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Contact No</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                      {selectedRow.mobileNumber}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Location</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                        {employeeData?.Location ? employeeData.Location : "-"}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <div className="employeefirstName">Sub-Location</div>
                    </Grid>
                    <Grid item md={1}>
                      :
                    </Grid>
                    <Grid item md={6.5}>
                      <span className="employeefield">
                        {employeeData?.SubLocation
                          ? employeeData.SubLocation
                          : "-"}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Address</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                        {employeeData?.address ? employeeData.address : "-"}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <div className="employeefirstName">Department </div>
                    </Grid>
                    <Grid item md={1}>
                      :
                    </Grid>
                    <Grid item md={6.5}>
                      <span className="employeefield">
                        {employeeData?.department ? employeeData.department : "-"}
                      </span>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={4} className="pt-10">
                      <div className="employeefirstName">Direct No</div>
                    </Grid>
                    <Grid item md={1} className="pt-10">
                      :
                    </Grid>
                    <Grid item md={6.5} className="pt-10">
                      <span className="employeefield">
                        {employeeData?.DirectNo ? employeeData.DirectNo : "-"}
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            )}
          </Box>
        </Drawer> */}
        <Drawer
          anchor="right"
          open={isProfileDrawerOpen}
          // onClose={handleCloseProfileDrawer}
        >
          <div className="containerheader11">
          <div>
                  <Tooltip placement="top-start" title="Click to Close">
                    <CloseRoundedIcon
                     onClick={handleCloseProfileDrawer}
                      style={{
                        cursor: "pointer",
                        color: "whitesmoke",
                        padding: "5px",
                        borderRadius: "50%",
                        transition: "background-color 0.3s",
                        height: "16px",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          "rgba(255, 255, 255, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    />
                  </Tooltip>
                </div>
            <div className="containerheader12">
              <img
                src={byrneIcon}
                alt="Remo Digital"
                style={{
                  width: "85px",
                  height: "42px",
                  top: "30px",
                  position: "relative",
                  borderRadius: "5px",
                }}
              />
            </div>
        
          </div>
          <Box sx={{ width: 300, padding: 2 }}>
            {selectedRow && (
              <div>
                <div>
                  <Avatar
                    alt={selectedRow.name}
                    src={selectedRow.profilePhoto}
                    className="profilePhoto"
                    sx={{
                      width: 130,
                      height: 130,
                      display: "flex",
                      bottom: "80px",
                      left: "24px",
                      Color: "white", // Set background color to white
                      border: "8px solid #ffffff", // Add border for circular shape
                      borderRadius: "50%", // Make it circular
                    }}
                  />
                  {/* <img
                    src={Small}
                    className="SmallIcon"
                    alt="Small Icon"
                    onClick={handleCloseProfileDrawer}
                  /> */}
                  <div>
                    {/* <div
                      className="LargeFontSize"
                      style={{
                        position: "absolute",
                        top: "70px",
                        left: "160px",
                        transform: "translateX(-50%)",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      <div style={{ color: "#423E44", fontSize: "22px" }}>
                        {selectedRow.name}
                      </div>
                      <div className="WhitecolorBlocksParagraph">
                        {" "}
                        {selectedRow.department}
                      </div>
                    </div> */}
                  </div>
                </div>
                <div style={{ marginLeft: "12px", marginTop: "-120px" }}>
                  <div style={{ color: "#423E44", fontSize: "22px",marginTop:"50px" }}>
                    {selectedRow.name}
                  </div>
                  <div className="WhitecolorBlocksParagraph">
                    {" "}
                    {selectedRow.department}
                  </div>
                </div>
                <div className="basicdetails">
                  <Typography
                    style={{
                      fontSize: "23px",
                      fontWeight: "bold !important",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    <span
                      style={{
                        color: "#FFDF00",
                        borderBottom: "2px solid #FFDF00",
                      }}
                    >
                      B
                    </span>
                    <span
                      style={{
                        color: "#3e3e3e !important",
                        borderBottom: " 2px solid #FFDF00",
                      }}
                    >
                      asic Details
                    </span>
                  </Typography>

                  <Box sx={{ flexGrow: 1 }} style={{ paddingBottom: "30px" }}>
                    {selectedRow && (
                      <Stack spacing={2} className="basic">
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Employee Name</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                                {selectedRow.name}
                              </span>
                            </Grid>

                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Extension Number</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                                {selectedRow?.Ext ? selectedRow.Ext : "-"}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Designation</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                                {selectedRow.designation}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Email</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                                {selectedRow.email}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Contact No</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                                {selectedRow.mobileNumber}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Location</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                              {selectedRow.Location}
                                {/* {employeeData?.Location ? employeeData.Location : "-"} */}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5}>
                              <div className="employeefirstName">Sub-Location</div>
                            </Grid>
                            <Grid item md={1}>
                              :
                            </Grid>
                            <Grid item md={5.5}>
                              <span className="employeefield">
                                {/* {employeeData?.SubLocation
                                  ? employeeData.SubLocation
                                  : "-"} */}
                              {selectedRow.SubLocation}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Address</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                              {selectedRow.address}
                                {/* {employeeData?.address ? employeeData.address : "-"} */}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5}>
                              <div className="employeefirstName">Department </div>
                            </Grid>
                            <Grid item md={1}>
                              :
                            </Grid>
                            <Grid item md={5.5}>
                              <span className="employeefield">
                              {selectedRow.department}
                                {/* {employeeData?.department ? employeeData.department : "-"} */}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div>
                          <Grid container spacing={2}>
                            <Grid item md={5} className="pt-10">
                              <div className="employeefirstName">Direct No</div>
                            </Grid>
                            <Grid item md={1} className="pt-10">
                              :
                            </Grid>
                            <Grid item md={5.5} className="pt-10">
                              <span className="employeefield">
                              {selectedRow.DirectNo}
                                {/* {employeeData?.DirectNo ? employeeData.DirectNo : "-"} */}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </Stack>
                    )}
                  </Box>
                </div>
              </div>
            )}
          </Box>
        </Drawer>
        {/* </Box> */}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#e0001b",
            fontWeight: "600",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "73px",
            marginRight: "135px",
            fontSize: "14px !important",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </div>
  );
};
export default EmployeeEditor;
