// import React, { useState, Fragment, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Checkbox,
//   CircularProgress,
//   FormControlLabel,
//   FormGroup,
//   Grid,
//   List,
//   Menu,
//   MenuItem,
//   Paper,
//   Stack,
//   Typography,
// } from "@mui/material";
// import { Link } from "react-router-dom";
// import { styled } from "@mui/material/styles";
// import "./Styles.css";
// import { EventType } from "../../types/event";
// import filterIcon from "../../Assets/eventSearch/filter icon.svg";
// import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
// import {
//   useGetActiveEventsQuery,
//   useGetEventsByReferenceQuery,
//   useGetEventsDataQuery,
// } from "../../Services/APIs";
// import moment from "moment";
// import EventsIcon from "../../Assets/NewByrneIcons/calendar 3.svg";

// interface EventCalanderListProps {
//   handleDateChange: (date: Date | Date[]) => void;
//   tileClassName: (date: any) => "highlightedDate" | null;
//   selectedDate: Date | null;
//   selectedEvents: EventType[];
//   isLoading: boolean;
//   // Other props...
// }

// interface Events {
//   _id: string;
//   eventTitle: string;
//   eventImage: string;
//   eventDescription: string;
//   eventsReference: string;
//   //   createdDate: string;
//   isActive: boolean;
//   eventDate: string;
//   eventEndDate: string;
//   eventTime: string;
//   updatedAt: string | number | Date;
//   eventOrganizedBy: string;
// }

// const EventsFilter: React.FC<EventCalanderListProps> = ({
//   selectedDate,
//   selectedEvents,
//   isLoading,
// }) => {
//   // Filter events by isActive status
//   const { data: EventsData } = useGetEventsDataQuery();
//   const {
//     data: activeEventsData,
//     error: activeEventsError,
//     isLoading: activeEventsisLoading,
//   } = useGetActiveEventsQuery();
//   // const eventsReferencesArray = activeEventsData ? activeEventsData.map((item:any) => item.eventsReference) : [];
//   console.log(activeEventsData, "activeEventsData:");

//   const [eventsReference, setEventsReference] = useState<string>("");
//   const [eventsReferences, setEventsReferences] = useState<string[]>([]);
//   const {
//     data: activeEventsDataByReference,
//     error: activeEventsDataByReferenceError,
//     isLoading: activeEventsDataByReferenceLoading,
//     refetch,
//   } = useGetEventsByReferenceQuery(eventsReference);

//   const filteredEvents = selectedEvents.filter((event) => event.isActive);
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   const [selectedLabel, setSelectedLabel] = useState("");
//   // const [selectedLabels, setSelectedLabels] = useState<string[]>([
//   //   "Announcement",
//   //   "Festival",
//   //   "Events",
//   //   "Local Events",
//   //   "Corporate Event",
//   // ]);
//   const [appliedLabels, setAppliedLabels] = useState<string[]>([]);
//   const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
//   const [isHovered, setIsHovered] = useState(false);
//   const [isChecked, setIsChecked] = useState(false);

//   const open = Boolean(anchorEl);
//   // const filteredEventsData =
//   //   EventsData?.result.filter((event: Events) => event.isActive) || [];
//   useEffect(() => {
//     if (eventsReference) {
//       refetch();
//     }
//   }, [eventsReference, refetch]);

//   useEffect(() => {
//     if (activeEventsData) {
//       // Only set appliedLabels, do not set selectedLabels initially
//       setAppliedLabels([...activeEventsData]);
//     }
//   }, [activeEventsData]);
//   // const today = moment().startOf('day');
//   const today = moment().startOf("day");
//   const filteredEventsData =
//     EventsData?.result?.filter(
//       (event: Events) =>
//         event.isActive && moment(event.eventEndDate).isSameOrAfter(today)
//     ) || [];

//   useEffect(() => {
//     if (activeEventsData) {
//       setAppliedLabels([...activeEventsData]);
//     }
//   }, [activeEventsData]);

//   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   const handleApplyFilter = () => {
//     setAppliedLabels(selectedLabels);
//     handleClose();
//   };
//   // const handleLabelClick = (label: string) => {
//   //   setSelectedLabels((prevSelectedLabels) =>
//   //     prevSelectedLabels.includes(label)
//   //       ? prevSelectedLabels.filter((item) => item !== label)
//   //       : [...prevSelectedLabels, label]
//   //   );
//   // };
//   const handleLabelClick = (label: string) => {
//     const updatedLabels = selectedLabels.includes(label)
//       ? selectedLabels.filter((item) => item !== label)
//       : [...selectedLabels, label];

//     setSelectedLabels(updatedLabels);
//     setAppliedLabels(updatedLabels); // Apply the filter based on the selected labels
//   };
//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };
//   // const handleSelectAll = () => {
//   //   if (isChecked) {
//   //     handleClear();
//   //   } else {
//   //     setIsChecked(true);
//   //     setSelectedLabels([
//   //       "Announcement",
//   //       "Festival",
//   //       "Events",
//   //       "Local Events",
//   //       "Corporate Event",
//   //     ]);
//   //   }
//   // };
//   // const handleSelectAll = () => {
//   //   if (isChecked) {
//   //     handleClear();
//   //   } else {
//   //     setIsChecked(true);
//   //     setSelectedLabels([...activeEventsData]);
//   //   }
//   // };

//   const handleSelectAll = () => {
//     if (isChecked) {
//       setIsChecked(false);
//       setSelectedLabels([]);
//       setAppliedLabels([]); // Clear applied labels when deselecting all
//     } else {
//       setIsChecked(true);
//       setSelectedLabels(activeEventsData);
//       setAppliedLabels([...activeEventsData]);
//     }
//   };
//   const handleClear = () => {
//     setSelectedLabels([]);
//     setIsChecked(false);
//   };

//   const handleOk = () => {
//     handleClose();
//   };
//   const eventsReferenceColors: { [key: string]: string } = {
//     Events: "grey",
//     Festival: "#762E7F",
//     "Local Events": "#221E1F",
//     " Announcements": "#179949",
//     "Corporate Event": "#ED2026",
//   };
//   const filteredByLabelEvents =
//     appliedLabels.length > 0
//       ? filteredEventsData.filter((event) =>
//           appliedLabels.includes(event.eventsReference)
//         )
//       : filteredEventsData;

//   // const firstTenEvents = filteredByLabelEvents.slice(0, 10);
//   const eventsToDisplay = filteredByLabelEvents;
//   // const eventsToDisplay = filteredByLabelEvents.sort((a, b) => {
//   //   if (moment(a.eventDate).isSame(today, 'day')) return -1;
//   //   if (moment(b.eventDate).isSame(today, 'day')) return 1;
//   //   return moment(a.eventDate).diff(moment(b.eventDate));
//   // });
//   function truncateDescription(description: any, wordLimit: any) {
//     const words = description.split(" ");
//     if (words.length > wordLimit) {
//       return words.slice(0, wordLimit).join(" ") + "...";
//     }
//     return description;
//   }
//   return (
//     <Paper
//       className="main-filter-events"
//       style={{ marginBottom: "20px", borderRadius: "8px !important" }}
//     >
//       <Box className="event-f-date">
//         {selectedDate ? (
//           selectedDate.toDateString()
//         ) : (
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <div
//               className="Header"
//               style={{ display: "flex", alignItems: "center" }}
//             >
//               <img
//                 src={EventsIcon}
//                 alt="Contactdirectory"
//                 style={{ marginRight: "10px", width: "15px", height: "auto" }}
//               />
//               <span> Byrne Event</span>
//             </div>
//             <div>
//               <Button
//                 id="basic-button"
//                 aria-controls={open ? "basic-menu" : undefined}
//                 aria-haspopup="true"
//                 aria-expanded={open ? "true" : undefined}
//                 onClick={handleClick}
//                 onMouseEnter={handleMouseEnter}
//                 onMouseLeave={handleMouseLeave}
//               >
//                 <img
//                   src={isHovered || open ? filterHoverIcon : filterIcon}
//                   alt="filter"
//                 />
//               </Button>
//               <Menu
//                 id="basic-menu"
//                 anchorEl={anchorEl}
//                 open={open}
//                 onClose={handleClose}
//                 MenuListProps={{
//                   "aria-labelledby": "basic-button",
//                 }}
//                 transformOrigin={{ horizontal: "right", vertical: "top" }}
//                 anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
//               >
//                 <div style={{ padding: "10px" }}>
//                   <div
//                     style={{ display: "flex", justifyContent: "space-between" }}
//                   >
//                     <div
//                       style={{
//                         fontSize: "14px",
//                         color: "#8c8c8f",
//                         fontWeight: "600",
//                         paddingBottom: "10px",
//                         paddingLeft: "10px",
//                       }}
//                     >
//                       Filter
//                     </div>
//                   </div>
//                   <FormGroup>
//                     <FormControlLabel
//                       className="filterLabels"
//                       key={"selectall"}
//                       control={
//                         <Checkbox
//                           checked={isChecked}
//                           color="default"
//                           style={{ padding: "0px 9px 0px" }}
//                         />
//                       }
//                       label={"Select All"}
//                       onClick={() => handleSelectAll()}
//                       style={{
//                         // color: selectedLabels.includes(label)
//                         //   ? eventsReferenceColors[label]
//                         //   : "#8c8f",
//                         fontSize: "12px!important",
//                       }}
//                     />
//                     {activeEventsData &&
//                       activeEventsData.map((label: any) => (
//                         <FormControlLabel
//                           key={label}
//                           control={
//                             <Checkbox
//                               checked={selectedLabels.includes(label)}
//                               color="default"
//                               style={{ padding: "0px 9px 0px" }}
//                             />
//                           }
//                           label={label}
//                           className={
//                             selectedLabels.includes(label)
//                               ? "filterLabels selected-label"
//                               : "filterLabels"
//                           }
//                           onClick={() => handleLabelClick(label)}
//                           style={{
//                             // color: selectedLabels.includes(label)
//                             //   ? eventsReferenceColors[label]
//                             //   : "#8c8f",
//                             fontSize: "12px!important",
//                           }}
//                         />
//                       ))}
//                   </FormGroup>
//                 </div>
//                 <Button
//                   className="createtwo"
//                   variant="contained"
//                   onClick={handleApplyFilter}
//                   // style={{
//                   //   fontSize:"10px",
//                   //   height:"20px",
//                   //   textTransform: 'capitalize',
//                   //   backgroundColor: '#e0001b',
//                   //   marginLeft: 'auto',
//                   // }}
//                 >
//                   Apply
//                 </Button>
//               </Menu>
//               <span>
//                 {" "}
//                 <Link className="viewEven" to="/viewAllEvents">
//                   View All
//                 </Link>
//               </span>
//             </div>
//           </div>
//         )}
//       </Box>
//       {isLoading ? (
//         <div>
//           <CircularProgress />
//         </div>
//       ) : null}
//       {!isLoading && eventsToDisplay.length > 0 ? (
//         <div style={{ maxHeight: "288px" }} className="eventspage">
//           {eventsToDisplay.map((event, index) => (
//             <React.Fragment key={event._id}>
//               <List className="customlistforeventcalander">
//                 <div>
//                   <Stack
//                     direction="row"
//                     padding="8px 0px 8px 0px"
//                     borderBottom="1px solid #e6e6e6"
//                     spacing={2}
//                   >
//                     <div>
//                       <Link
//                         to={`/viewAllEvents/${event._id}`}
//                         style={{ textDecoration: "none" }}
//                       >
//                         <div className="event-date-box">
//                           <Typography
//                             style={{
//                               color: "green",
//                               fontSize: "16px",
//                               textAlign: "center",
//                               fontWeight: "600",
//                               paddingTop: "7px",
//                             }}
//                           >
//                             {moment(event.eventDate).format("DD") ===
//                             moment(event.eventEndDate).format("DD")
//                               ? moment(event.eventDate).format("DD")
//                               : `${moment(event.eventDate).format(
//                                   "DD"
//                                 )} - ${moment(event.eventEndDate).format(
//                                   "DD"
//                                 )}`}
//                           </Typography>
//                           <Typography
//                             style={{
//                               color: "green",
//                               fontSize: "14px",
//                               textAlign: "center",
//                             }}
//                           >
//                             {moment(event.eventDate).format("MMMM")}
//                           </Typography>
//                           <div className="new-time">{event.eventTime}</div>
//                         </div>
//                       </Link>
//                     </div>
//                     <div>
//                       <Grid>
//                         <Typography
//                           style={{
//                             color: "#6c6d70",
//                             borderRadius: "5px",
//                             padding: "3px",
//                             fontSize: " 14px ",
//                             // fontFamily: "Calibri regular",
//                             fontFamily: "Calibri Regular",
//                             marginBottom: "5px",
//                             fontWeight: "600",
//                             display: "-webkit-box",
//                             WebkitLineClamp: 2,
//                             WebkitBoxOrient: "vertical",
//                             overflow: "hidden",
//                             textOverflow: "ellipsis",
//                           }}
//                         >
//                           {truncateDescription(event.eventTitle, 10)}
//                         </Typography>
//                         <div className="eventType-filter">
//                           <Typography
//                             className="news-readmore-type"
//                             style={{
//                               backgroundColor:
//                                 eventsReferenceColors[event.eventsReference] ||
//                                 "#179949",
//                               width: "100%",
//                             }}
//                           >
//                             <Fragment>
//                               {truncateDescription(event.eventsReference, 10)}
//                             </Fragment>
//                           </Typography>
//                         </div>

//                         {/* <Typography
//                           className="eventcalanderDescription"
//                           dangerouslySetInnerHTML={{
//                              __html: truncateDescription(
//                               event.eventDescription,
//                               15
//                             ),
//                           }}
//                         /> */}
//                       </Grid>
//                     </div>
//                   </Stack>
//                 </div>
//               </List>
//             </React.Fragment>
//           ))}
//         </div>
//       ) : (
//         <div className="container">
//           <p className="typographyStyles2">No events for the selected date.</p>
//         </div>
//       )}
//     </Paper>
//   );
// };

// export default EventsFilter;

import React, { useState, Fragment, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import "./Styles.css";
import { EventType } from "../../types/event";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
import {
  useGetActiveEventsQuery,
  useGetEventsByReferenceQuery,
  useGetEventsDataQuery,
} from "../../Services/APIs";
import moment from "moment";
import EventsIcon from "../../Assets/NewByrneIcons/calendar 3.svg";

interface EventCalanderListProps {
  handleDateChange: (date: Date | Date[]) => void;
  tileClassName: (date: any) => "highlightedDate" | null;
  selectedDate: Date | null;
  selectedEvents: EventType[];
  isLoading: boolean;
}

interface Events {
  _id: string;
  eventTitle: string;
  eventImage: string;
  eventDescription: string;
  eventsReference: string;
  isActive: boolean;
  eventDate: string;
  eventEndDate: string;
  eventTime: string;
  updatedAt: string | number | Date;
  eventOrganizedBy: string;
}

const EventsFilter: React.FC<EventCalanderListProps> = ({
  selectedDate,
  selectedEvents,
  isLoading,
}) => {
  const { data: EventsData } = useGetEventsDataQuery();
  const {
    data: activeEventsData,
    error: activeEventsError,
    isLoading: activeEventsisLoading,
  } = useGetActiveEventsQuery();

  const [eventsReference, setEventsReference] = useState<string>("");
  const [eventsReferences, setEventsReferences] = useState<string[]>([]);
  const {
    data: activeEventsDataByReference,
    error: activeEventsDataByReferenceError,
    isLoading: activeEventsDataByReferenceLoading,
    refetch,
  } = useGetEventsByReferenceQuery(eventsReference);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [appliedLabels, setAppliedLabels] = useState<string[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const open = Boolean(anchorEl);

  const today = moment().startOf("day");
  const filteredEventsData =
    EventsData?.result?.filter(
      (event: Events) =>
        event.isActive && moment(event.eventEndDate).isSameOrAfter(today)
    ) || [];

  useEffect(() => {
    if (eventsReference) {
      refetch();
    }
  }, [eventsReference, refetch]);

  useEffect(() => {
    if (activeEventsData) {
      setAppliedLabels([...activeEventsData]);
    }
  }, [activeEventsData]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyFilter = () => {
    setAppliedLabels(selectedLabels);
    handleClose();
  };

  const handleLabelClick = (label: string) => {
    const updatedLabels = selectedLabels.includes(label)
      ? selectedLabels.filter((item) => item !== label)
      : [...selectedLabels, label];

    setSelectedLabels(updatedLabels);
    setAppliedLabels(updatedLabels);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSelectAll = () => {
    if (isChecked) {
      setIsChecked(false);
      setSelectedLabels([]);
      setAppliedLabels([]);
    } else {
      setIsChecked(true);
      setSelectedLabels(activeEventsData);
      setAppliedLabels([...activeEventsData]);
    }
  };

  const eventsReferenceColors: { [key: string]: string } = {
    Events: "grey",
    Festival: "#762E7F",
    "Local Events": "#221E1F",
    " Announcements": "#179949",
    "Corporate Event": "#ED2026",
  };

  const filteredByLabelEvents =
    appliedLabels.length > 0
      ? filteredEventsData.filter((event) =>
          appliedLabels.includes(event.eventsReference)
        )
      : filteredEventsData;

  const monthOrder = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const groupAndSortEventsByMonth = (events: Events[]) => {
    // const groupedEvents = events.reduce((acc, event) => {
    //   const month = moment(event.eventDate).format('MMMM');
    //   if (!acc[month]) acc[month] = [];
    //   acc[month].push(event);
    //   return acc;
    // }, {} as Record<string, Events[]>);

    // return monthOrder.flatMap(month => groupedEvents[month] || []);
    return events.sort(
      (a, b) =>
        new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
    );
  };

  const eventsToDisplay = groupAndSortEventsByMonth(filteredByLabelEvents);

  function truncateDescription(description: string, wordLimit: number) {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  }

  return (
    <Paper
      className="main-filter-events"
      style={{ marginBottom: "20px", borderRadius: "8px !important" }}
    >
      <Box className="event-f-date">
        {selectedDate ? (
          selectedDate.toDateString()
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="Header"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={EventsIcon}
                alt="Contactdirectory"
                style={{ marginRight: "10px", width: "15px", height: "auto" }}
              />
              <span> Byrne Event</span>
            </div>
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={isHovered || open ? filterHoverIcon : filterIcon}
                  alt="filter"
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div style={{ padding: "10px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#8c8c8f",
                        fontWeight: "600",
                        paddingBottom: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      Filter
                    </div>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      className="filterLabels"
                      key={"selectall"}
                      control={
                        <Checkbox
                          checked={isChecked}
                          color="default"
                          style={{ padding: "0px 9px 0px" }}
                        />
                      }
                      label={"Select All"}
                      onClick={() => handleSelectAll()}
                      style={{
                        fontSize: "12px!important",
                      }}
                    />
                    {activeEventsData &&
                      activeEventsData.map((label: any) => (
                        <FormControlLabel
                          key={label}
                          control={
                            <Checkbox
                              checked={selectedLabels.includes(label)}
                              color="default"
                              style={{ padding: "0px 9px 0px" }}
                            />
                          }
                          label={label}
                          className={
                            selectedLabels.includes(label)
                              ? "filterLabels selected-label"
                              : "filterLabels"
                          }
                          onClick={() => handleLabelClick(label)}
                          style={{
                            fontSize: "12px!important",
                          }}
                        />
                      ))}
                  </FormGroup>
                </div>
                <Button
                  className="createtwo"
                  variant="contained"
                  onClick={handleApplyFilter}
                >
                  Apply
                </Button>
              </Menu>
              <span>
                <Link className="viewEven" to="/viewAllEvents">
                  View All
                </Link>
              </span>
            </div>
          </div>
        )}
      </Box>
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : null}
      <div  className="eventspage">
      {!isLoading && eventsToDisplay.length > 0 ? (
        eventsToDisplay.map((event) => (
          <React.Fragment key={event._id}>
            <List className="customlistforeventcalander">
              <div style={{ maxHeight: "288px" }}>
                <Stack
                  direction="row"
                  padding="8px 0"
                  borderBottom="1px solid #e6e6e6"
                  spacing={2}
                >
                  <div>
                    <Link
                      to={`/viewAllEvents/${event._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="event-date-box">
                        <Typography
                          style={{
                            color: "green",
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: "600",
                            paddingTop: "7px",
                          }}
                        >
                          {moment(event.eventDate).format("DD") ===
                          moment(event.eventEndDate).format("DD")
                            ? moment(event.eventDate).format("DD")
                            : `${moment(event.eventDate).format("DD")} - ${moment(event.eventEndDate).format("DD")}`}
                        </Typography>
                        <Typography
                          style={{
                            color: "green",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          {moment(event.eventDate).format("MMMM")}
                        </Typography>
                        <div className="new-time">{event.eventTime}</div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Grid>
                      <Typography
                        style={{
                          color: "#6c6d70",
                          borderRadius: "5px",
                          padding: "3px",
                          fontSize: "14px",
                          fontFamily: "Calibri Regular",
                          marginBottom: "5px",
                          fontWeight: "600",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {truncateDescription(event.eventTitle, 10)}
                      </Typography>
                      <div className="eventType-filter">
                        <Typography
                          className="news-readmore-type"
                          style={{
                            backgroundColor:
                              eventsReferenceColors[event.eventsReference] || "#179949",
                            width: "100%",
                          }}
                        >
                          {truncateDescription(event.eventsReference, 10)}
                        </Typography>
                      </div>
                    </Grid>
                  </div>
                </Stack>
              </div>
            </List>
          </React.Fragment>
        ))
      ) : (
        <div className="container">
          <p className="typographyStyles2">No events for the selected date.</p>
        </div>
      )}
    </div>
    </Paper>
  );
};

export default EventsFilter;
