import React, { useEffect, useMemo, useState } from "react";
import "./ItRequestStyles.css";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListIcon from "@mui/icons-material/FilterList";
import approveIcon from "../../Assets/Byrne - icons/Approved.svg";
import rejectIcon from "../../Assets/Byrne - icons/Rejected.svg";
import pendingIcon from "../../Assets/Byrne - icons/Pending.svg";
import SearchIcon from "../../Assets/it-request/search-icon.png";
import exportIcon from "../../Assets/Byrne - icons/Excel.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { CSVLink } from "react-csv";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Pagination,
  PaginationItem,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { RequestType, RequestEditorProps } from "../../types/ItRequest";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import line from "../../Assets/it-request/Line1.png";
import {
  useGetItRequestsDataQuery,
  useGetItRequestByRequestedIdQuery,
  useGetRequestTypesQuery,
  useGetEntitiesQuery,
  useGetLocationsDataQuery,
  useGetOverallStatusCountQuery,
  useGetRequestsByLoggedInEmailQuery,
} from "../../Services/APIs";
import LoaderComponent from "../../hooks/LoaderComponent";
import { useNavigate } from "react-router-dom";
import { useApproveOrRejectWorkflowRunMutation } from "../../Services/workFlowAPIs";
import IconText from "../IconText/IconText";
import * as XLSX from "xlsx";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { format } from "date-fns";
import useLoggedInUser from "../Hooks/useLoggedInUser";
import { useLocation } from "react-router-dom";
interface GridValidRowModel {
  overallStatus: any;
  _id: GridRowId;
  id: any;
  requestedId: string;
  subject: string;
  requestedBy: string;
  requestedOn: string;
  assignedTo: string;
  status: string;
  approvalStatus: string;
  requestType: string;
  entity: string;
  location: string;
}

interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px !important" }} onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);

// const ItRequests = () => {
const ItRequests: React.FC<RequestEditorProps> = ({ RequestType }) => {
  const navigate = useNavigate();
  // const {
  //   data: loggedData,
  //   // error,
  //   // isLoading,
  //   // isSuccess,
  //   // refetch: refetchITRequestsData,
  // } = useGetItRequestsDataQuery();

  const { employeeData, isEmployeeDataLoading } = useLoggedInUser();
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchITRequestsData,
  } = useGetRequestsByLoggedInEmailQuery(employeeData?.employeeEmail || "", {
    skip: !employeeData?.employeeEmail || isEmployeeDataLoading,
  });


  const location = useLocation();
  // refresh screen when routing from the approve/reject screens
  useEffect(() => {
    if (location.state?.refresh) {
      console.log("Refreshing main screen");
      refetchITRequestsData();
      
      // Reset the refresh flag by navigating with updated state
    navigate(location.pathname, {
      replace: true, // This ensures the current history entry is replaced
      state: { ...location.state, refresh: false }, // Reset the refresh flag
    });
    }
  }, [location.state, navigate]);

  console.log(employeeData, data, "dataRequests");

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);
  // console.log(data, "get all IT request data's:");
  const [selectedRequestTypes, setSelectedRequestTypes] = useState<string[]>(
    []
  );
  const { data: RequestTypeData } = useGetRequestTypesQuery();
  const { data: EntityData } = useGetEntitiesQuery();
  const { data: LocationData } = useGetLocationsDataQuery();
  console.log(RequestTypeData, EntityData, LocationData, "all type data");
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedRequestedId, setSelectedRequestedId] = useState<string | null>(
    null
  );
  const {
    data: getITRequestFormDetailsUsingRequestId,
    error: getFormDatausingRequestIdError,
    isLoading: getFormDatausingRequestIdisLoading,
  } = useGetItRequestByRequestedIdQuery(selectedRequestedId || "", {
    skip: !selectedRequestedId,
  });
  console.log(
    getITRequestFormDetailsUsingRequestId,
    "getITRequestFormDetailsUsingRequestId"
  );
  const [status, setStatus] = useState("");
  // const { data:getOverallStatusCount } = useGetOverallStatusCountQuery(status);
  // Handle the click event on request ID
  // const handleRequestIdClick = (requestedId: string) => {
  //   setSelectedRequestedId(requestedId); // Set the selected request ID
  // };
  const [activeButton, setActiveButton] = useState("All");

  const [approveOrRejectWorkflowRun] = useApproveOrRejectWorkflowRunMutation();

  const handleRequestIdClick = (
    requestedId: string,
    approvalStatus: string,
    nextApprovalEmail: string[],
  ) => {

    console.log(requestedId, approvalStatus, nextApprovalEmail, employeeData?.employeeEmail, 'checkIDStatus')
    if (nextApprovalEmail?.includes(employeeData?.employeeEmail)) {
      if (approvalStatus === "Submitted") {
        navigate(`/ITRequest-Approve-Reject_form-FirstApprover/${requestedId}`);
      } else if (approvalStatus === "First Level Approved") {
        navigate(`/ITRequest-Approve-Reject_form-SecondApprover/${requestedId}`);
      } else if (approvalStatus === "Second Level Approved") {
        navigate(`/ITRequest-Approve-Reject_form-ThirdApprover/${requestedId}`);
      } else if (
        approvalStatus === "First Level Rejected" ||
        "Second Level Rejected" ||
        "Third Level Rejected"
      ) {
        navigate(`/ITRequest-Approve/Rejected_ViewForm/${requestedId}`);
      }
    } else {
      navigate(`/ITRequest-Approve/Rejected_ViewForm/${requestedId}`);
    }
    // if (approvalStatus === "Submitted") {
    //   navigate(`/ITRequest-Approve-Reject_form-FirstApprover/${requestedId}`);
    // } else if (approvalStatus === "First Level Approved") {
    //   navigate(`/ITRequest-Approve-Reject_form-SecondApprover/${requestedId}`);
    // } else if (approvalStatus === "Second Level Approved") {
    //   navigate(`/ITRequest-Approve-Reject_form-ThirdApprover/${requestedId}`);
    // } else if (
    //   approvalStatus === "First Level Rejected" ||
    //   "Second Level Rejected" ||
    //   "Third Level Rejected"
    // ) {
    //   navigate(`/ITRequest-Approve/Rejected_ViewForm/${requestedId}`);
    // } else {
    //   console.log("Unhandled approval status:", approvalStatus);
    // }
  };

  const handleRequestIdViewClick = (
    // requestedId: string,
    requestedId: string
    // approvalStatus: string
  ) => {
    navigate(`/ITRequest-Approve/Rejected_ViewForm/${requestedId}`);
  };
  // if (approvalStatus === "Submitted") {
  //   navigate(`/ITRequest-Approve-Reject_form/${requestedId}`);
  // } else if (approvalStatus === "First Level Approved") {
  //   navigate(`/ITRequest-Approve-Reject_form-SecondApprover/${requestedId}`);
  // } else if (approvalStatus === "Second Level Approved") {
  //   navigate(`/ITRequest-Approve-Reject_form-ThirdApprover/${requestedId}`);
  // } else if (
  //   approvalStatus === "First Level Rejected" ||
  //   "Second Level Rejected" ||
  //   "Third Level Rejected"
  // ) {
  //   navigate(`/ITRequest-Rejected_ViewForm/${requestedId}`);
  // } else {
  //   console.log("Unhandled approval status:", approvalStatus);
  // }
  // };
  const tableData = data && data.requests ? data.requests : [];

  const [searchQuery, setSearchQuery] = useState("");
  const [submittedQuery, setSubmittedQuery] = useState("");
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [reqData, setReqData] = useState<RequestType[] | null>(null);
  const [dataId, setDataId] = useState<any>("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  console.log(tableData, "requestData it request");

  const [rejectedCount, setRejectedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);

  const { data: approvedData, refetch: refetchApproved } =
    useGetOverallStatusCountQuery("Completed");
  const { data: pendingData, refetch: refetchPending } =
    useGetOverallStatusCountQuery("Pending");
  const { data: rejectedData, refetch: refetchRejected } =
    useGetOverallStatusCountQuery("Rejected");
  // const { data: completedData } = useGetOverallStatusCountQuery("Completed");
  // console.log(completedData, "completedData:");
  const [selected3, setSelected3] = useState("Apply");
  // useEffect(() => {
  //   if (approvedData) {
  //     setApprovedCount(approvedData.count);
  //   }
  //   if (pendingData) {
  //     setPendingCount(pendingData.count);
  //   }
  //   if (rejectedData) {
  //     setRejectedCount(rejectedData.count);
  //   }
  // }, [approvedData, pendingData, rejectedData]);
  // useEffect(() => {
  //   // refetchITRequestsData();
  //   // Refetch the data whenever the component mounts
  //   refetchApproved();
  //   refetchPending();
  //   refetchRejected();
  // }, []);
  // Function to handle status filtering
  const handleRequestTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    requestType: string
  ) => {
    if (event.target.checked) {
      setSelectedRequestTypes([...selectedRequestTypes, requestType]);
    } else {
      setSelectedRequestTypes(
        selectedRequestTypes.filter((type) => type !== requestType)
      );
    }
  };

  const handleEntityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    entity: string
  ) => {
    if (event.target.checked) {
      setSelectedEntities([...selectedEntities, entity]);
    } else {
      setSelectedEntities(selectedEntities.filter((ent) => ent !== entity));
    }
  };

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    location: string
  ) => {
    if (event.target.checked) {
      setSelectedLocations([...selectedLocations, location]);
    } else {
      setSelectedLocations(selectedLocations.filter((loc) => loc !== location));
    }
  };

  const handleFilterByStatus = (status: any) => {
    if (status) {
      const filtered = rows.filter((row) => row.status === status);
      setFilteredRows(filtered);
    } else {
      setFilteredRows(rows); // Show all data if no status is provided
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  // const handleToggle = (
  //   btn: string,
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setSelected3(btn);
  // };
  // useEffect(() => {
  //   if (rows && RequestTypeData && EntityData && LocationData) {
  //     const filtered = rows.filter((row) => {
  //       const matchesRequestType =
  //         selectedRequestTypes.length === 0 ||
  //         selectedRequestTypes.includes(row.requestType);
  //       const matchesEntity =
  //         selectedEntities.length === 0 || selectedEntities.includes(row.entity);
  //       const matchesLocation =
  //         selectedLocations.length === 0 ||
  //         selectedLocations.includes(row.location);

  //       return matchesRequestType && matchesEntity && matchesLocation;
  //     });

  //     setFilteredRows(filtered);
  //   }
  // }, [searchQuery, rows, selectedRequestTypes, selectedEntities, selectedLocations]);

  const handleToggle = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected3(btn);
    // If the "Apply" button is clicked, apply filters
    if (btn === "Apply") {
      const filtered = tableData.filter((row: any) => {
        const matchesRequestType =
          selectedRequestTypes.length === 0 ||
          selectedRequestTypes.includes(row.requestType);
        const matchesEntity =
          selectedEntities.length === 0 ||
          selectedEntities.includes(row.entity);
        const matchesLocation =
          selectedLocations.length === 0 ||
          selectedLocations.includes(row.location);

        return matchesRequestType && matchesEntity && matchesLocation;
      });

      setFilteredRows(filtered);
      handleDrawerClose();
    }
    if (btn === "Clear") {
      // Reset the selected fields
      setSelectedRequestTypes([]);
      setSelectedEntities([]);
      setSelectedLocations([]);

      // Reset the filtered rows to the original rows
      //setFilteredRows(rows);
      setFilteredRows(tableData);
      // handleDrawerClose();
    }
  };

  // const handleSearch = () => {
  //   const filtered = rows.filter((row) =>
  //     Object.values(row).some((value) =>
  //       String(value).toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  //   setFilteredRows(filtered);
  // };
  const handleEditClick = (id: any) => {
    // setOpenOne(true);
    setIsEditMode(true);
    console.log(id, "editId");
    setDataId(id);
    const data = rows.filter((i: any) => {
      return i.id == id;
    });
  };

  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [openOne, setOpenOne] = React.useState<boolean>(false);
  const handleClickOpen = () => {
    setOpenOne(true);
    // setOpenPreview(false);
    // resetFormFields();
  };

  const handleClose = () => {
    setOpenOne(false);
    // setIsEditMode(false);
  };
  const handleDrawerClose = () => {
    setOpenOne(false);
  };
  const handleCancel = () => {
    handleDrawerClose();
  };
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    const tableData = data && data.requests ? data.requests : [];
    if (isSuccess && tableData?.length > 0) {
      console.log("Fetched data:", tableData); // Log the raw data to check the structure

      const formattedRows = tableData?.map((item: any, index: number) => {
        // Clean the item keys by removing double quotes
        const cleanedItem: Record<string, any> = {};
        Object.keys(item).forEach((key) => {
          const cleanedKey = key.replace(/"/g, "");
          cleanedItem[cleanedKey] = item[key];
        });
        const formattedDate = cleanedItem.createdAt
          ? format(new Date(cleanedItem.createdAt), "dd/MM/yyyy")
          : "N/A";

        let assignedTo = "N/A";
        if (cleanedItem.approvalStatus === "Submitted") {
          assignedTo = cleanedItem.firstApprover ?? "N/A";
        } else if (cleanedItem.approvalStatus === "First Level Approved") {
          assignedTo = cleanedItem.secondApprover ?? "N/A";
        } else if (cleanedItem.approvalStatus === "Second Level Approved") {
          assignedTo = cleanedItem.thirdApproverAdmin ?? "N/A";
        } else if (cleanedItem.approvalStatus === "Completed") {
          assignedTo = cleanedItem.thirdApproverAdmin ?? "N/A";
        } else if (cleanedItem.approvalStatus === "Third Level Rejected") {
          assignedTo = cleanedItem.thirdApproverAdmin ?? "N/A";
        } else if (cleanedItem.approvalStatus === "Second Level Rejected") {
          assignedTo = cleanedItem.secondApprover ?? "N/A";
        } else if (cleanedItem.approvalStatus === "First Level Rejected") {
          assignedTo = cleanedItem.firstApprover ?? "N/A";
        }
        return {
          _id: cleanedItem._id,
          id: index + 1,
          requestedId: cleanedItem.requestedId ?? "N/A",
          subject: cleanedItem.subject ?? "N/A",
          requestedBy: cleanedItem.requestedBy ?? "N/A",
          // requestedOn: cleanedItem.requestedOn ?? "N/A",
          requestedOn: formattedDate,
          // assignedTo: cleanedItem.assignedTo ?? "N/A",
          assignedTo: assignedTo,
          status: cleanedItem.status ?? "N/A",
          approvalStatus: cleanedItem.approvalStatus ?? "N/A",
          overallStatus: cleanedItem.overallStatus ?? "N/A",
          requestType: cleanedItem.requestType ?? "N/A",
          entity: cleanedItem.entity ?? "N/A",
          location: cleanedItem.location ?? "N/A",
          nextApproverEmail: cleanedItem?.nextApproverEmail

        };
      });

      console.log("Formatted rows:", formattedRows); // Log the formatted rows
      setRows(formattedRows);
      setFilteredRows(formattedRows);
      // Calculate the counts
      const rejected = formattedRows.filter(
        (row: any) => row.status === "Rejected"
      ).length;
      const pending = formattedRows.filter(
        (row: any) => row.status === "Pending"
      ).length;
      const approved = formattedRows.filter(
        (row: any) => row.status === "Approved"
      ).length;

      setRejectedCount(data?.counts?.rejected);
      setPendingCount(data?.counts?.pending);
      setApprovedCount(data?.counts?.completed);
    }
  }, [isSuccess, data,]);

  // useEffect(() => {
  //   const filtered = rows.filter(
  //     (row) =>
  //       (row.requestedId &&
  //         row.requestedId.toLowerCase().includes(searchQuery)) ||
  //       (row.subject && row.subject.toLowerCase().includes(searchQuery)) ||
  //       (row.requestedBy &&
  //         row.requestedBy.toLowerCase().includes(searchQuery)) ||
  //       (row.assignedTo &&
  //         row.assignedTo.toLowerCase().includes(searchQuery)) ||
  //       (row.status && row.status.toLowerCase().includes(searchQuery))
  //   );
  //   setFilteredRows(filtered);
  // }, [searchQuery, rows]);
  // useEffect(() => {
  //   const filtered = rows.filter((row) => {
  //     const matchesRequestType =
  //       selectedRequestTypes.length === 0 ||
  //       selectedRequestTypes.includes(row.requestType);
  //     const matchesEntity =
  //       selectedEntities.length === 0 || selectedEntities.includes(row.entity);
  //     const matchesLocation =
  //       selectedLocations.length === 0 ||
  //       selectedLocations.includes(row.location);
  //     const matchesSearch = searchQuery
  //       ? (row.requestedId &&
  //           row.requestedId.toLowerCase().includes(searchQuery)) ||
  //         (row.subject && row.subject.toLowerCase().includes(searchQuery)) ||
  //         (row.requestedBy &&
  //           row.requestedBy.toLowerCase().includes(searchQuery)) ||
  //         (row.assignedTo &&
  //           row.assignedTo.toLowerCase().includes(searchQuery)) ||
  //         (row.status && row.status.toLowerCase().includes(searchQuery)) ||
  //         (row.requestType &&
  //           row.requestType.toLowerCase().includes(searchQuery)) ||
  //         (row.requestedOn &&
  //           row.requestedOn.toLowerCase().includes(searchQuery)) ||
  //         (row.entity && row.entity.toLowerCase().includes(searchQuery)) ||
  //         (row.location && row.location.toLowerCase().includes(searchQuery))
  //       : true;

  //     return (
  //       matchesRequestType && matchesEntity && matchesLocation && matchesSearch
  //     );
  //   });

  //   setFilteredRows(filtered);
  // }, [
  //   searchQuery,
  //   rows,
  //   selectedRequestTypes,
  //   selectedEntities,
  //   selectedLocations,
  // ]);



  // useEffect(() => {
  //   const filtered = rows.filter((row) => {
  //     const matchesRequestType =
  //       selectedRequestTypes.length === 0 ||
  //       selectedRequestTypes.includes(row.requestType);

  //     const matchesEntity =
  //       selectedEntities.length === 0 || selectedEntities.includes(row.entity);

  //     const matchesLocation =
  //       selectedLocations.length === 0 ||
  //       selectedLocations.includes(row.location);

  //     const matchesSearch = searchQuery
  //       ? [
  //           row.requestedId,
  //           row.subject,
  //           row.requestedBy,
  //           row.assignedTo,
  //           row.approvalStatus,
  //           row.requestType,
  //           row.entity,
  //           row.location,
  //           row.requestedOn, // Assuming this is part of the data
  //         ]
  //           .filter((field) => field) // Remove null or undefined fields
  //           .some((field) =>
  //             field.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //           )
  //       : true;

  //     return (
  //       matchesRequestType && matchesEntity && matchesLocation && matchesSearch
  //     );
  //   });

  //   setFilteredRows(filtered);
  // }, [
  //   searchQuery,
  //   rows,
  //   selectedRequestTypes,
  //   selectedEntities,
  //   selectedLocations,
  // ]);


  useEffect(() => {
    const filterByCriteria = (row: any) => {
      const matchesRequestType = !selectedRequestTypes?.length || selectedRequestTypes?.includes(row.requestType);
      const matchesEntity = !selectedEntities?.length || selectedEntities?.includes(row.entity);
      const matchesLocation = !selectedLocations?.length || selectedLocations?.includes(row.location);

      // Apply status filter only if it’s not "All"
      const matchesStatus = activeButton === "All" || (
        activeButton === "Rejected"
          ? row.approvalStatus?.includes("Rejected")
          : row.approvalStatus === activeButton
      );

      // Search query filter
      const matchesSearch = searchQuery
        ? [row.requestedId, row.subject, row.requestedBy, row.assignedTo, row.approvalStatus, row.requestType, row.entity, row.location, row.requestedOn]
          ?.filter(Boolean) // Remove null or undefined fields
          ?.some((field) => field?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
        : true;

      return matchesRequestType && matchesEntity && matchesLocation && matchesStatus && matchesSearch;
    };

    // Filter the rows based on all criteria
    const filteredRows = rows.filter(filterByCriteria);
    setFilteredRows(filteredRows);
  }, [searchQuery, rows, selectedRequestTypes, selectedEntities, selectedLocations, activeButton]);

  const handleSearch = (event: any) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };
  console.log(rows, filteredRows, "rorss");
  const handleFilterChange = (event: any) => {
    const value = event.target.value.toLowerCase();
    setFilterValue(value);
  };

  const paginatedData = filteredRows.slice(
    (page - 1) * pageSize,
    page * pageSize
  );
  //const paginatedData = filteredRows.slice((page - 1) * pageSize, page * pageSize)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [columnHeaders, setcolumnHeaders] = React.useState<any>({
    id: true,
    requestedId: true,
    subject: true,
    requestedBy: true,
    requestedOn: true,
    assignedTo: true,
    status: true,
    approvalStatus: true,
    overallStatus: true,
    requestType: true,
    entity: true,
    location: true,
  });
  const handleExport = () => {
    console.log("columnHeaders:", columnHeaders);
    const tableData = data && data.requests ? data.requests : [];

    // Mapping through table data to format rows
    const formattedRows = tableData.map((item: any) => {
      const cleanedItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        const cleanedKey = key.replace(/"/g, ""); // Remove quotes from keys if necessary
        cleanedItem[cleanedKey] = item[key]; // Assign cleaned data
      });
      const formattedDate = cleanedItem.createdAt
        ? format(new Date(cleanedItem.createdAt), "dd/MM/yyyy")
        : "N/A";
      let assignedTo = "N/A";
      if (cleanedItem.approvalStatus === "Submitted") {
        assignedTo = cleanedItem.firstApprover ?? "N/A";
      } else if (cleanedItem.approvalStatus === "First Level Approved") {
        assignedTo = cleanedItem.secondApprover ?? "N/A";
      } else if (cleanedItem.approvalStatus === "Second Level Approved") {
        assignedTo = cleanedItem.thirdApproverAdmin ?? "N/A";
      } else if (cleanedItem.approvalStatus === "Completed") {
        assignedTo = cleanedItem.thirdApproverAdmin ?? "N/A";
      } else if (cleanedItem.approvalStatus === "Third Level Rejected") {
        assignedTo = cleanedItem.thirdApproverAdmin ?? "N/A";
      } else if (cleanedItem.approvalStatus === "Second Level Rejected") {
        assignedTo = cleanedItem.secondApprover ?? "N/A";
      } else if (cleanedItem.approvalStatus === "First Level Rejected") {
        assignedTo = cleanedItem.firstApprover ?? "N/A";
      }
      let exportData: Record<string, any> = {}; // Prepare data for export
      if (columnHeaders["id"]) exportData["ID"] = cleanedItem._id; // Always export ID
      if (columnHeaders["requestedId"])
        exportData["Requested Id"] = cleanedItem.requestedId ?? "N/A";
      if (columnHeaders["subject"])
        exportData["Subject"] = cleanedItem.subject ?? "N/A";
      if (columnHeaders["requestedBy"])
        exportData["Requested By"] = cleanedItem.requestedBy ?? "N/A";
      if (columnHeaders["requestedOn"])
        exportData["Requested On"] = formattedDate ?? "N/A";
      if (columnHeaders["assignedTo"])
        exportData["Assigned To"] = assignedTo ?? "N/A";
      if (columnHeaders["status"])
        exportData["Status"] = cleanedItem.approvalStatus ?? "N/A";
      if (columnHeaders["approvalStatus"])
        exportData["Approval Status"] = cleanedItem.approvalStatus ?? "N/A";
      if (columnHeaders["overallStatus"])
        exportData["Overall Status"] = cleanedItem.overallStatus ?? "N/A";
      if (columnHeaders["requestType"])
        exportData["Request Type"] = cleanedItem.requestType ?? "N/A";
      if (columnHeaders["entity"])
        exportData["Entity"] = cleanedItem.entity ?? "N/A";
      if (columnHeaders["location"])
        exportData["Location"] = cleanedItem.location ?? "N/A";

      return exportData; // Return formatted row for export
    });

    // Create a new workbook and sheet with the formatted rows
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedRows);

    // Calculate and set the column widths dynamically
    const colWidths = formattedRows.reduce((acc: number[], row: any) => {
      Object.keys(row).forEach((key, i) => {
        const cellValue = row[key]?.toString() || "";
        const cellWidth = cellValue.length;
        if (!acc[i] || cellWidth > acc[i]) {
          acc[i] = cellWidth;
        }
      });
      return acc;
    }, []);

    // Apply the calculated column widths to the sheet
    ws["!cols"] = colWidths.map((width: any) => ({ wch: width }));

    // Append the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "ExportedData");

    // Write the workbook to an Excel file
    XLSX.writeFile(wb, "Dashboard Data.xlsx");
  };

  const columns: GridColDef<GridValidRowModel>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 30,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
    },
    {
      field: "requestedId",
      headerName: "Requested Id",
      width: 120,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
      renderCell: (params: any) => (
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: "500",
          }}
          onClick={() => {
            handleRequestIdClick(params.value, params.row.approvalStatus, params?.row?.nextApproverEmail)
          }}
        >
          {truncateDescription(params.value, 10)}
        </span>
      ),
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 100,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
    },
    {
      field: "requestedBy",
      headerName: "Requested By",
      width: 100,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
    },
    {
      field: "requestedOn",
      headerName: "Requested On",
      width: 130,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 100,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      width: 130,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          {/* <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: "auto" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </div>
      ),
    },
    {
      field: "requestType",
      headerName: "Request Type",
      width: 200,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          {/* <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: "auto" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </div>
      ),
    },
    {
      field: "entity",
      headerName: "Entity",
      width: 100,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
    },
    {
      field: "location",
      headerName: "Location",
      width: 100,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      valueGetter: (params: any) => params.value || "----",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "TableHeader",
      cellClassName: "EventsTableCell",
      sortable: true,
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
        </div>
      ),
      renderCell: (params: any) => (
        <span
          style={{ textDecoration: "none", cursor: "pointer" }}
          onClick={() => handleRequestIdViewClick(params.row.requestedId)}
        >
          View
        </span>
      ),
    },
  ];
  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };

  const getCellClassName = () => {
    return "eve";
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleMenuItemClick = (buttonName: string) => {
    setActiveButton(buttonName);
    handleMenuClose();
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleTooltipOpen = () => {
    if (!dropdownOpen) {
      setTooltipOpen(true);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleButtonClick = (status: any) => {
    handleTooltipClose();
    setActiveButton(status);
  };

  // const handleButtonClick = (status: any) => {
  //   handleTooltipClose();
  //   setActiveButton(status);
  //   let filtered;
  //   // Check if the status is "Rejected"
  //   if (status === "Rejected") {
  //     // Include rows with any "Rejected" status
  //     filtered = rows?.filter((row) =>
  //       row.approvalStatus?.includes("Rejected")
  //     );
  //   } else if (status === "All") {
  //     // If "All" is selected, show all rows
  //     filtered = rows;
  //   } else {
  //     // Otherwise, filter rows with exact match to the status
  //     filtered = rows?.filter((row) => row.approvalStatus === status);
  //   }
  //   setFilteredRows(filtered);
  // };


  const handleDropdownOpen = () => {
    setDropdownOpen(true);
    handleTooltipClose(); // Close tooltip when the dropdown opens
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  console.log(paginatedData, 'checkPaginatedData')

  // if (!tableData) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "50vh",
  //       }}
  //     >
  //       <LoaderComponent />
  //     </div>
  //   );
  // }

  let count = 0;
  if (data) {
    count = data.count;
  }
  function truncateDescription(description: string, letterLimit: number) {
    if (description.length > letterLimit) {
      return description.slice(0, letterLimit) + "...";
    }
    return description;
  }
  //  const [searchQuery, setSearchQuery] = useState("");

  //   const handleSearchChange = (e) => {
  //     setSearchQuery(e.target.value);
  //   };

  const clearSearch = () => {
    setSearchQuery("");
  };
  return (
    <div>
      <IconText />
      <Grid
        spacing={2}
        item
        xs={12}
        md={12}
        style={{
          backgroundColor: "#f1f1f1",
          // marginTop: "0px",
          // height: "80vh",
          // overflowY: "scroll",
        }}
      >
        <div>
          <Grid
            item
            xs={12}
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
            }}
            className="dashboardDiv"
          >
            <div className="dashboard-upper">
              <div className="dashboard-text">DASHBOARD</div>
              <div className="request-btn">
                <Link
                  to="/newRequestForm"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  New Request
                </Link>
              </div>
            </div>
          </Grid>
          {/* <Grid
            item
            xs={12}
            className="icons-parts"
            style={{
              marginTop: "-60px",
              paddingLeft: "50px",
              paddingTop: "15px",
            }}
          > */}

          {/* {isLoading ? ( */}
          {(isEmployeeDataLoading || isLoading) ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh', // Full viewport height
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <div
                className="icons-parts"
                style={{ position: "sticky", top: 50, zIndex: 1000 }}
              // style={{
              //   marginTop: "-50px",
              //  padding:"15px 30px",
              // }}
              >
                <div className="approveList">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={approveIcon}
                      alt="approve"
                      style={{ width: "60px", paddingRight: "10px" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#423E44",
                          fontWeight: "bold",
                          fontSize: "22px",
                          marginTop: "-8px",
                        }}
                      >
                        {approvedCount}
                      </Typography>

                      <Typography
                        style={{
                          color: "#423E44",
                          fontSize: "14px",
                          lineHeight: "15px",
                        }}
                      >
                        Completed
                      </Typography>
                    </Box>
                  </div>
                  <img src={line} alt="hash" className="line" />

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={pendingIcon}
                      alt="approve"
                      style={{ width: "60px", paddingRight: "10px" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#423E44",
                          fontWeight: "bold",
                          fontSize: "22px",
                          marginTop: "-8px",
                        }}
                      >
                        {pendingCount}
                      </Typography>

                      <Typography
                        style={{
                          color: "#423E44",
                          fontSize: "14px",
                          lineHeight: "15px",
                        }}
                      >
                        Pending
                      </Typography>
                    </Box>
                  </div>
                  <img src={line} alt="hash" className="line" />

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={rejectIcon}
                      alt="approve"
                      style={{ width: "60px", paddingRight: "10px" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#423E44",
                          fontWeight: "bold",
                          fontSize: "22px",
                          marginTop: "-8px",
                        }}
                      >
                        {rejectedCount}
                      </Typography>

                      <Typography
                        style={{
                          color: "#423E44",
                          fontSize: "14px",
                          lineHeight: "15px",
                        }}
                      >
                        Rejected
                      </Typography>
                    </Box>
                  </div>
                </div>
                {/* <List className="approveList">
                <ListItem>
                  <ListItemAvatar
                    style={{ width: "60px", paddingRight: "10px" }}
                  >
                    <img
                      src={approveIcon}
                      alt="approve"
                      style={{ width: "100%" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={approvedCount}
                    // secondary={setStatus="Approved"}
                    secondary="Approved"
                    primaryTypographyProps={{
                      style: {
                        color: "#423E44",
                        fontWeight: "bold",
                        fontSize: "25px",
                        marginTop: "-8px",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { color: "#b1b1b1", fontSize: "16px" },
                    }}
                  />
                </ListItem>
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={line} alt="hash" className="line" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar
                    style={{ width: "60px", paddingRight: "10px" }}
                  >
                    <img
                      src={pendingIcon}
                      alt="approve"
                      style={{ width: "100%" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={pendingCount}
                    // secondary={setStatus="Pending"}
                    secondary="Pending"
                    primaryTypographyProps={{
                      style: {
                        color: "#423E44",
                        fontWeight: "bold",
                        fontSize: "25px",
                        marginTop: "-8px",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { color: "#b1b1b1", fontSize: "16px" },
                    }}
                  />
                </ListItem>
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={line} alt="hash" className="line" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar className="lastItem">
                    <img
                      src={rejectIcon}
                      alt="approve"
                      style={{ width: "100%" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={rejectedCount}
                    // secondary={setStatus="Rejected"}
                    secondary="Rejected"
                    primaryTypographyProps={{
                      style: {
                        color: "#423E44",
                        fontWeight: "bold",
                        fontSize: "25px",
                        marginTop: "-8px",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { color: "#b1b1b1", fontSize: "16px" },
                    }}
                  />
                </ListItem>
              </List> */}
              </div>
              {/* </Grid> */}
              <Grid item xs={12} style={{ paddingTop: "0px" }}>
                <div className="search-Div">
                  <div className="search-part">
                    <div className="search-first-div">
                      <Paper
                        component="form"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: 350,
                          boxShadow: "none",
                          backgroundColor: "#ffffff",
                          padding: "2px 10px",
                          height: "32px",
                          borderRadius: "4px",
                        }}
                      >
                        {/* <IconButton
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    > */}
                        <img
                          src={SearchIcon}
                          alt="Search"
                          className="searchIcon-it"
                        />
                        {/* </IconButton> */}
                        <InputBase
                          sx={{
                            ml: 1,
                            flex: 1,
                            fontSize: "14px",
                            color: "#000000",
                          }}
                          inputProps={{ "aria-label": "search" }}
                          value={searchQuery}
                          onChange={handleSearchChange}
                          placeholder="Search user here..."
                        />
                        {searchQuery && (
                          <IconButton
                            sx={{ p: "3px" }}
                            aria-label="clear"
                            onClick={clearSearch}
                          >
                            <ClearOutlinedIcon />
                          </IconButton>
                        )}
                      </Paper>

                      <div className="m-auto">
                        <Tooltip title="More Filters">
                          <Button className="filter-div" onClick={handleClickOpen}>
                            <FilterListIcon />
                            {/* <span className="spanText">More Filters</span> */}
                          </Button>
                        </Tooltip>
                      </div>
                      <Tooltip
                        title="Status"
                        open={tooltipOpen && !dropdownOpen}
                        onOpen={handleTooltipOpen}
                        onClose={handleTooltipClose}
                      >
                        <div
                          className="all-menu"
                          onMouseEnter={handleTooltipOpen}
                          onMouseLeave={handleTooltipClose}
                        >
                          <FormControl size="small">
                            <Select
                              id="all-select"
                              value={activeButton}
                              onChange={(event) =>
                                handleButtonClick(event.target.value)
                              }
                              onOpen={handleDropdownOpen} // Close tooltip when dropdown opens
                              onClose={handleDropdownClose}
                              className="all-Select"
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                                minWidth: "120px",
                                ".MuiSelect-select": {
                                  height: "auto",
                                  minHeight: "33px !important",
                                },
                                ".MuiInputBase-input": {
                                  padding: "8px 11px 7px 7px",
                                },
                              }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    boxShadow:
                                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                                  },
                                },
                              }}
                            >
                              {/* Tooltip on specific item */}
                              {/* <Tooltip
                            title="Status"
                            open={tooltipOpen}
                            onOpen={handleTooltipOpen}
                            onClose={handleTooltipClose}
                          >
                            <span>All</span>
                          </Tooltip>
                         */}

                              {[
                                "All",
                                "Submitted",
                                "First Level Approved",
                                "Second Level Approved",
                                "Rejected",
                                "Completed",
                              ].map((buttonName) => (
                                <MenuItem
                                  key={buttonName}
                                  value={buttonName}
                                  sx={{
                                    textAlign: "left",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "30px",
                                  }}
                                >
                                  {buttonName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Tooltip>
                    </div>
                    <Tooltip title="Download Here" placement="top">
                      <div className="export-div">
                        {/* <span>
                </span> */}
                        <span className="export-btn">
                          {" "}
                          {/* <CSVLink
                        data={tableData}
                        filename={"table-data.csv"}
                        style={{ textDecoration: "none", color: "#423e44" }}
                      > */}
                          <img
                            src={exportIcon}
                            alt=""
                            style={{
                              // width: "15px",
                              // height: "20px",
                              paddingRight: "0px",
                            }}
                            onClick={handleExport}
                          />
                          {/* </CSVLink> */}
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
              <div>
                <Drawer
                  anchor={"right"}
                  classes={{
                    paper: "newPosOfDialog1",
                  }}
                  open={openOne}
                  onClose={handleClose}
                >
                  <div className="DrawerHeaderBox">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {/* <div onClick={handleClose}>
                  <AddCircleOutlineRoundedIcon
                    style={{ marginTop: "4px", color: "#ffffff" }}
                  />
                </div> */}
                      <div className="DrawerTitle">
                        <Typography>Filter</Typography>
                      </div>
                    </Stack>
                    {/* <div> */}
                    <CloseRoundedIcon
                      onClick={handleCancel}
                      style={{
                        cursor: "pointer",
                        color: "whitesmoke",
                        padding: "5px",
                        borderRadius: "50%",
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          "rgba(255, 255, 255, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    />
                    {/* </div> */}
                  </div>

                  <Divider />
                  <Typography className="text-b">REQUEST TYPE</Typography>
                  <Box>
                    <FormGroup>
                      {RequestTypeData?.map((req: any) => (
                        <FormControlLabel
                          className="filterLabels"
                          key={req._id}
                          control={
                            <Checkbox
                              checked={selectedRequestTypes.includes(
                                req.requestType
                              )}
                              onChange={(e) =>
                                handleRequestTypeChange(e, req.requestType)
                              }
                              color="default"
                              style={{ padding: "0px 9px 0px" }}
                            />
                          }
                          label={req.requestType}
                          style={{
                            fontSize: "12px!important",
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                  <Typography className="text-b">ENTITY</Typography>
                  <Box>
                    <FormGroup>
                      {EntityData?.map((entity: any) => (
                        <FormControlLabel
                          className="filterLabels"
                          key={entity._id}
                          control={
                            <Checkbox
                              checked={selectedEntities.includes(entity.entity)}
                              onChange={(e) => handleEntityChange(e, entity.entity)}
                              color="default"
                              style={{ padding: "0px 9px 0px" }}
                            />
                          }
                          label={entity.entity}
                          style={{
                            fontSize: "12px!important",
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                  <Typography className="text-b">LOCATION</Typography>
                  <Box style={{ height: "200px", overflowY: "scroll" }}>
                    <FormGroup>
                      {LocationData?.map((location: any) => (
                        <FormControlLabel
                          className="filterLabels"
                          key={location._id}
                          control={
                            <Checkbox
                              checked={selectedLocations.includes(
                                location.location
                              )}
                              onChange={(e) =>
                                handleLocationChange(e, location.location)
                              }
                              color="default"
                              style={{ padding: "0px 9px 0px" }}
                            />
                          }
                          label={location.location}
                          style={{
                            fontSize: "12px!important",
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                  {/* <Grid sx={{ m: 2, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "30px",
                }}
              >
                <Button
                  // onClick={handleClose}
                  onClick={(event) => handleToggle("Clear", event)}
                  className={selected3 === "Clear" ? "selected" : "notSelected"}
                >
                  Clear
                </Button>
                <Button
                  onClick={(event) => handleToggle("Apply", event)}
                  className={selected3 === "Apply" ? "selected" : "notSelected"}
                  style={{ marginLeft: "30px" }}
                >
                  Apply
                </Button>
              </div>
            </Grid> */}
                </Drawer>
              </div>
              <div className="table-over">
                <Grid item xs={12} md={12} style={{ paddingTop: "0px" }}>
                  <div className="status">
                    {/* <div
              style={{
                width: "300px",
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0px",
              }}
              className="status-inner-div"
            >
              {["All", "Pending", "Approved", "Rejected", "Completed"].map(
                (buttonName) => (
                  <span
                    key={buttonName}
                    className={`all-btn ${
                      activeButton === buttonName ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick(buttonName)}
                    style={{
                      margin: "0 5px", // Optional: Adjust this margin if needed
                    }}
                  >
                    {buttonName}
                  </span>
                )
              )}
            </div> */}
                    <Box
                      sx={{
                        flexGrow: 1,
                        width: "100%",
                        height: "400px",
                      }}
                    >
                     {paginatedData?.length > 0 && ( <DataGrid
                        getRowId={(row) => row._id}
                        rows={paginatedData}
                        columns={columns}
                        sx={{
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#FDE4E5 !important",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                          border: "none !important",
                          ".MuiDataGrid-cellContent": {
                            pointerEvents: "none",
                          },
                        }}
                        componentsProps={{
                          footer: { style: { display: "none" } },
                        }}
                        disableRowSelectionOnClick
                        disableDensitySelector
                        disableColumnFilter={false}
                        disableColumnMenu
                        hideFooterPagination={false}
                        disableColumnSelector={true}
                        getRowClassName={getRowClassName}
                        getCellClassName={getCellClassName}
                      />
                      )}
                    </Box>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px",
                        // marginTop: "-82px",
                        marginBottom: "35px",
                      }}
                    >
                      {tableData?.length > 0 ? (
                        <>
                          {/* Show the range of data only when there is data */}
                          <Typography
                            variant="body2"
                            sx={{ marginRight: "10px", color: "gray" }}
                          >
                            {`${(page - 1) * pageSize + 1} - ${Math.min(
                              page * pageSize,
                              //   tableData.length
                              // )} of ${tableData.length}`}
                              filteredRows?.length
                            )} of ${filteredRows?.length}`}
                          </Typography>

                          {/* Render the pagination when there is data */}
                          <Pagination
                            // count={Math.ceil(tableData.length / pageSize)}
                            count={Math.ceil(filteredRows.length / pageSize)}
                            shape="rounded"
                            page={page}
                            onChange={handlePageChange}
                            renderItem={(item) => (
                              <PaginationItem
                                components={{
                                  previous: (props) => (
                                    <IconButton {...props} size="small">
                                      <ArrowBackIosIcon fontSize="small" />
                                    </IconButton>
                                  ),
                                  next: (props) => (
                                    <IconButton {...props} size="small">
                                      <ArrowForwardIosIcon fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                                {...item}
                              />
                            )}
                            sx={{
                              "& .MuiPaginationItem-root": {
                                color: "#666",
                                "&.Mui-selected": {
                                  backgroundColor: "#e0001b",
                                  color: "white",
                                  fontWeight: "bold",
                                  "&:hover": {
                                    backgroundColor: "#e0001b",
                                  },
                                },
                              },
                              "& .MuiIconButton-root": {
                                color: "#666",
                              },
                            }}
                          />
                        </>
                      ) : (
                        // Display "No data available" when no rows match the filter
                        <Typography
                          variant="body2"
                          sx={{ marginRight: "10px", color: "gray" }}
                        >
                          No data available.
                        </Typography>
                      )}
                    </div>
                  </div>
                </Grid>
              </div>
            </>
            )}
        </div>
      </Grid>
    </div>
  );
};

export default ItRequests;
function handleFilterIconClick(
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  field: any
): void {
  throw new Error("Function not implemented.");
}
